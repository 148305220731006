function Footer(){
    return (
        <footer className="bootom-footer">
            <div className="container text-center">
                <span>Copyright © Berries n’ Blossoms</span>
            </div>
        </footer>
    )
}

export default Footer