import React , { useState , useEffect } from "react";
import ReactDOM from "react-dom";
import { Link , useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Axios from 'axios'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Swal = withReactContent(swal)



function Cart() {


        const [carts , setCarts] = useState(0)
        const [loading , setLoading] = useState(false)
        useEffect(() => {
            async function loadCart(){
            let newObject = localStorage.getItem("cart");
            setCarts(newObject ? JSON.parse(newObject).length : 0)
            }
            loadCart()
        } , [carts])

        const [phone , setPhone] = useState(null)
        const navigate = useNavigate()
        const dataObj = {token: localStorage.getItem('token')}

        Axios.post('https://berriesblossoms.com:3000/get/user' , dataObj).then(res => {
           if(res.data.status == 'success'){
                setPhone(res.data.user.phone)
           }
        })

        var hours = React.createRef();
        hours = (e) => {
            for (let h = 0; h < 24; h++) {
                let ht = h < 10 ? '0'+h : h.toString()
                if(ht == '13'){
                    e.innerHTML += '<option selected value="'+ht+'">'+ht+'</option>'
                }else{
                    e.innerHTML += '<option value="'+ht+'">'+ht+'</option>'
                }
            }
        }

        let mycartsARR = localStorage.getItem("cart");
        const [mycarts , setMyCarts] = useState(mycartsARR ? JSON.parse(mycartsARR) : [])

        let total = 0
        const cartElements = mycarts.map((product , index) => {
            total += product.price * product.amount
            let image = product.cover_image;
            let theme_color
            if(product.cart_type != undefined){
                image = 'store_custom.jpg'
                theme_color = <span>โทนสี : {product.color}</span>
            }
            var divStyle = {
                backgroundImage: 'url(https://berriesblossoms.com:3001/'+ image + ')'
            };
            let typeNameArr = {original:"Original" , choco_berries:'Choco Berries' , yusu_blossoms: 'Yusu Blossoms' , yusu_premium:'Yusu Premium', fruity_berries:'Fruity Berries' , special_event:'Special Event'}
            let sizeNameArr = {
                simple: 'Simple',
                merry: 'Merry',
                love: 'Love',
                extralove: 'Extra Love',
                forever: 'Forever',
                moon_festival: 'Moon Festival',
                vegetarien: 'Vegetarien Festival',
                mother_day: "Mother's Day",
                special_size: 'Special Size',
                happy_berries: 'Happy Berries',
                chinese_new_year: 'Chinese New Year',
            }
            return <tr key={index} data-cartkey={index}>
                        <td><div style={divStyle} className="cart-image" /></td>
                        <td>
                            รสชาด : {typeNameArr[product.taste]}<br/>
                            ขนาด : {sizeNameArr[product.size]}<br/>
                            {theme_color}
                            <span className="sm-d-block">ราคา : {product.price} บาท<br/></span><br/>
                            <span className="sm-d-block">จำนวน : x{product.amount}<br/></span>
                        </td>
                        <td  className="sm-d-none">{product.price} บาท</td>
                        <td  className="sm-d-none">x{product.amount}</td>
                        <td><button type="button" onClick={() => deleteCart(index)} className="btn btn-sm btn-danger"><i className="fas fa-trash"></i></button></td>
                     </tr>
        })

        const deleteCart = (key) => {
            console.log(key)
            const index = mycarts.indexOf(key);
            mycarts.splice(index, 1);
            setMyCarts(mycarts)
            const tr = document.querySelector('[data-cartkey="'+key+'"]');
            tr.remove()
            localStorage.setItem('cart' , JSON.stringify(mycarts))
        }
        
        const checkout = (e) => {
            e.preventDefault()
            let newObject = localStorage.getItem("cart");
            let formCarts = JSON.parse(newObject)
            if(!newObject){
                Swal.fire({
                    title: <h4>คุณไม่มีสินค้าในตะกร้า</h4>,
                    html: "",
                    icon: "error"
                })
            }
            const address = document.getElementById('address').value
            const phone = document.getElementById('phone').value
            const date = document.getElementById('date').value
            const time = document.getElementById('time').value
            const wish = document.getElementById('wish').value
            const zone = document.getElementById('zone').value
            const dataObj = {token: localStorage.getItem('token')}
            Axios.post('https://berriesblossoms.com:3000/get/user' , dataObj).then(res => {
                var res = res.data
                if(res.status != 'success'){
                    Swal.fire({
                        title: <h4>กรุณาเข้าสู่ระบบก่อนสั่งซื้อ</h4>,
                        html: "",
                        icon: "error",
                        confirmButtonText: 'เข้าสู่ระบบ',
                    }).then(() => {
                        window.location.href = '/login'
                    })
                    return false
                }else{
                    const user_token = localStorage.getItem('token')
                    const formData = {user_token:user_token , address:address  , phone:phone , date:date, time:time, wish:wish, zone:zone , details:formCarts}
                    Axios.post('https://berriesblossoms.com:3000/create/order' , formData).then(res => {
                        var res = res.data
                        if(res.success){
                            localStorage.removeItem('cart')
                            navigate('/thankyou?code='+res.code)
                        }else{
                            Swal.fire({
                                title: <h4>แจ้งเตือน</h4>,
                                html: <h5>สั่งซื้อสินค้าไม่สำเร็จ โปรดลองใหม่อีกครั้ง</h5>,
                                icon: "error"
                            })
                        }
                    })
                }
            })
        }

        const [selectTime , setSelectTime] = useState(<select id="time" className="cart-form-control">
                                                            <option selected value="10:00 น.-11:00 น.">10:00 น.-11:00 น.</option>
                                                            <option value="14:00 น.-15:00 น.">14:00 น.-15:00 น.</option>
                                                            <option value="18:00 น.-19:00 น.">18:00 น.-19:00 น.</option>
                                                        </select>)
        function selectZone(e){
            if(e.target.value == 'กรุงเทพมหานคร'){
                setSelectTime(<select id="time" className="cart-form-control">
                                <option selected value="10:00 น.-11:00 น.">10:00 น.-11:00 น.</option>
                                <option value="14:00 น.-15:00 น.">14:00 น.-15:00 น.</option>
                                <option value="18:00 น.-19:00 น.">18:00 น.-19:00 น.</option>
                            </select>)
            }else{
                setSelectTime(<select id="time" className="cart-form-control">
                                <option selected value="อ.เมือง 13:00 น.-15:00 น.">อ.เมือง 13:00 น.-15:00 น.</option>
                                <option value="ต่างอำเภอ 15:00 น.-19:00 น.">ต่างอำเภอ 15:00 น.-19:00 น.</option>
                            </select>)
            }
        }

        const dateRef = React.createRef()
        const closeAlertModal = (e) => {
            dateRef.current.remove()
            const date = document.getElementById('date')
            date.click()
        }

        const date = new Date();
        date.setDate(date.getDate() + 5);
        // date.setMonth(date.getMonth() + 1);
       
        let month = date.getMonth() + 1
        if(month == 13){
            month = '01'
        }
        
        // const fullDate = date.getFullYear();
        const min = date.getFullYear()+'-'+month+'-'+date.getDate()
        const max = (date.getFullYear() + 1)+'-'+date.getMonth()+'-'+date.getDate()

       
        return(
            <div>
                <Header totalCart={mycarts.length}/>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-8 mb-3">
                            <h4>ตะกร้าสินค้า</h4>
                            <div className="cart-card">
                                <table className="table table-striped w-100">
                                    <thead>
                                        <tr>
                                            <th>รูปสินค้า</th>
                                            <th>รายละเอียดสินค้า</th>
                                            <th className="sm-d-none">ราคา/ชิ้น</th>
                                            <th className="sm-d-none">จำนวน</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartElements}
                                    </tbody>
                                    <tfoot className="sm-d-none">
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>รวม : {total} บาท</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div className="text-right sm-d-block">
                                    <span>รวม : {total} บาท</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <h4>ข้อมูลการจัดส่ง</h4>
                            <form onSubmit={checkout} className="cart-card">
                                <div className="cart-form-group">
                                    <label><i className="fas fa-map-marker-alt"></i> ที่อยู่ <span className="text-danger">*</span></label>
                                    <textarea className="cart-form-control" id="address" rows="2" required></textarea>
                                </div>
                                <div className="cart-form-group">
                                    <label><i className="fas fa-phone"></i> เบอร์โทรศัพท์ <span className="text-danger">*</span></label>
                                    <input type="text" value={phone} id="phone" className="cart-form-control" required/>
                                </div>
                                <div className="cart-form-group">
                                    <label><i className="fas fa-map-marker-alt"></i> โซนที่อยู่ <span className="text-danger">*</span></label>
                                    <select id="zone" onChange={selectZone} className="cart-form-control">
                                        <option selected value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
                                        <option value="ต่างจังหวัด">ต่างจังหวัด</option>
                                    </select>
                                </div>
                                <div className="row">
                                    <div className="cart-form-group col-12 position-relative"  >
                                        <label><i className="fas fa-calendar-week"></i> วันที่ต้องการรับ <span className="text-danger">*</span></label>
                                        <input type="date" id="date-modal" className="cart-form-control" ref={dateRef} data-bs-toggle="modal" data-bs-target="#myModal" />
                                        <input type="date" name="date" id="date"  min={min} max={max}   className="cart-form-control"   required/>
                                    </div>
                                    <div className="cart-form-group col-12">
                                        <label><i className="fas fa-clock"></i> เวลา<span className="text-danger">*</span></label>
                                        {selectTime}
                                    </div>
                                </div>
                                <div className="cart-form-group">
                                    <label><i className="fas fa-birthday-cake"></i> คำอวยพร (เขียนลงบนการ์ด)</label>
                                    <textarea className="cart-form-control" id="wish" rows="2"></textarea>
                                </div>
                                <button className="checkout-btn">สั่งสินค้า</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade " tabIndex="-1" id="myModal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                              <h4 className="text-danger text-center">เนื่องจากกระบวนการผลิตที่ใช้เวลาและ<br></br>ยอดการสั่งจองจำนวนมาก</h4>
                              <div class="alert alert-danger" role="alert">
                                 <h4 className="text-center mb-0">ขอความกรุณาสั่งล่วงหน้า อย่างน้อย 5 วันนะคะ</h4>
                              </div>
                              <div className="text-center">
                                 <button type="button"  data-bs-dismiss="modal" onClick={closeAlertModal} className="btn btn-success">ตกลง</button>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default Cart