import React , { useState , useContext , useEffect } from 'react';
import Axios from 'axios'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const Swal = withReactContent(swal)

function ProductForm(props) {
    const style = {
        left: '20px'
    }
    const queryParams = new URLSearchParams(window.location.search);
    const taste = queryParams.get('taste');
    const size = props.currentdata;
    const [myProducts , setMyProducts] = useState([])
    const [inputs , setInputs] = useState({})
    const [selectionGroup , setSelectionGroup] = useState([taste])
    const [simpleGroupEl , setSimpleGroupEl] = useState()
    const [simpleGroup , setSimpleGroup] = useState('rose')
    const selecteRef = React.createRef()
    const handleChangeGroup = (e) => {
        setSimpleGroup(selecteRef.current.value)
    }

    const handleCheckGroup = (e) => {
        const val = e.target.value
        const group = selectionGroup
        if(!group.includes(val)){
            group.push(val)
            if(val == 'simple'){
                setSimpleGroupEl(<div className='col-12 form-group'>
                    <label style={style}>หมวดหมู่สินค้า <span className='text-danger'>(กรณีเลือก Simple)</span></label>
                    <select className='form-control' ref={selecteRef} onChange={handleChangeGroup} name='group'>
                        <option value="rose" >กุหลาบ / Rose</option>
                        <option value="carnation" >คาร์เนชั่น / Carnation</option>
                        <option value="groups" >ดอกไม้กลุ่ม / Groups</option>
                        <option value="others" >ดอกไม้อื่นๆ / Others</option>
                        <option value="dahlia" >ดาห์เลีย / Dahlia</option>
                        <option value="water_lily" >บัวสาย / Water Lily</option>
                        <option value="lotus" >บัวหลวง / Lotus</option>
                        <option value="peony" >โบตั๋น / Peony</option>
                    </select>
                </div>)
            }
        }else{
            let index = group.indexOf(val);
            group.splice(index , 1)
            if(val == 'simple'){
                setSimpleGroupEl("")
            }
        }
        setSelectionGroup(group)
    }
  

    const handleAddChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setInputs(values => ({...values , [name]: value}))
    }
    
    const [selectedFile, setSelectedFile] = useState();
    const changeFileHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};
   
    const addProductSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData()
        if(taste == ""){
            Swal.fire({
                title: <strong>ระบบแจ้งเตือน !!</strong>,
                html: <small>กรุณาเลือกรสชาด</small>,
                icon: 'warning'
            })
            return false
        }
        if(size == ""){
            Swal.fire({
                title: <strong>ระบบแจ้งเตือน !!</strong>,
                html: <small>กรุณาเลือกขนาด</small>,
                icon: 'warning'
            })
            return false
        }

        formData.append('price' , inputs.price)
        formData.append('taste' , taste)
        formData.append('name' , inputs.name)
        formData.append('size' , size)
        var group = inputs.group == undefined ? 'rose' : inputs.group
        if(taste != 'yusu_premium' || taste != 'fruity_berries' || taste != 'special_event'){
            if(size == 'simple'){
                formData.append('group' , group)
            }
        }
        formData.append('cover_image' , selectedFile)
        if(!setSelectedFile){
            Swal.fire({
                title: <strong>ระบบแจ้งเตือน</strong>,
                html: <small>กรุณาเลือกรูปภาพ</small>,
                icon: 'error'
            })
            return false
        }
        // อัพโหลดรูปภาพ
        Axios.post('https://berriesblossoms.com:3001/uploads.php?type=one' , formData).then(imageRes => {
            if(imageRes.data.success){
                formData.append('image_name' , imageRes.data.cover_image)
                Axios.post('https://berriesblossoms.com:3000/add/product' , formData).then(res => {
                    if(res.data.success){
                        Swal.fire({
                            title: <strong>ระบบแจ้งเตือน</strong>,
                            html: <small>เพิ่มสินค้าสำเร็จ</small>,
                            icon: 'success'
                        }).then(() => {
                            window.location.reload(false);
                        })
                    }else{
                        Swal.fire({
                            title: <strong>ระบบแจ้งเตือน</strong>,
                            html: <small>เพิ่มสินค้าไม่สำเร็จโปรดลองใหม่อีกครั้ง</small>,
                            icon: 'error'
                        })
                    }
                })
            }else{
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>อัพโหลดรูปภาพไม่สำเร็จ</small>,
                    icon: 'error'
                })
            }
        })
    }

    var selectGroup
    if(taste == 'original' || taste == 'yusu_blossoms' || taste == 'choco_berries'){
        if(props.currentdata == 'simple'){
            selectGroup =   <div className='form-group'>
                                <label>หมวดหมู่สินค้า</label>
                                <select className='form-control' onChange={handleAddChange} defaultValue="rose" name='group'>
                                    <option value="rose" selected>กุหลาบ / Rose</option>
                                    <option value="carnation" >คาร์เนชั่น / Carnation</option>
                                    <option value="groups" >ดอกไม้กลุ่ม / Groups</option>
                                    <option value="others" >ดอกไม้อื่นๆ / Others</option>
                                    <option value="dahlia" >ดาห์เลีย / Dahlia</option>
                                    <option value="water_lily" >บัวสาย / Water Lily</option>
                                    <option value="lotus" >บัวหลวง / Lotus</option>
                                    <option value="peony" >โบตั๋น / Peony</option>
                                </select>
                            </div>
        }
    }

    return (
        <div className="modal fade" tabIndex="-1" id="myModal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">เพิ่มสินค้า</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={addProductSubmit}>
                            <div className="form-group-file mb-3">
                                <label>รูปสินค้า<span className="text-danger">*</span></label>
                                <br></br>
                                <input type="file" name="cover_image" onChange={changeFileHandler} className="form-control-file mt-2"  required/>
                            </div>
                            {selectGroup}
                            <div className="form-group">
                                <label>ชื่อสินค้า</label>
                                <input type="text" name="name" value={inputs.name || ""} onChange={handleAddChange} className="form-control"  />
                            </div>
                            <div className="form-group">
                                <label>ราคาสินค้า<span className="text-danger">*</span></label>
                                <input type="number" name="price" value={inputs.price || ""} onChange={handleAddChange} className="form-control"  required/>
                            </div>
                            <div className="modal-footer p-0 border-0">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">ยกเลิก</button>
                                <button type="submit" className="btn btn-primary">
                                    บันทึก
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductForm