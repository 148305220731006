import Header from "../components/Header"
import ProductForm from "../components/ProductForm";
import Axios from 'axios'
import { useParams , Link , useNavigate, withRouter } from 'react-router-dom';
import React, { useState, useContext, useEffect } from 'react';
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProductAdminCard from "../components/ProductAdminCard";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'; 
const Swal = withReactContent(swal)

function handleEditSubmit(e){
    e.preventDefault()
    const fileImage = document.getElementById('fileImage')
    const formData = new FormData(e.target)
    if(fileImage.files[0]){
        Axios.post('https://berriesblossoms.com:3001/uploads.php?type=one' , formData).then(imageRes => {
            if(imageRes.data.success){
                formData.append('image_name' , imageRes.data.cover_image)
                formData.append('fileImage' , true)
                Axios.post('https://berriesblossoms.com:3000/update/product' , formData).then(res => {
                    if(res.data.success){
                        Swal.fire({
                            title: <strong>ระบบแจ้งเตือน</strong>,
                            html: <small>บันทึกค้าสำเร็จ</small>,
                            icon: 'success'
                        }).then(() => {
                            window.location.reload(false);
                        })
                    }else{
                        Swal.fire({
                            title: <strong>ระบบแจ้งเตือน</strong>,
                            html: <small>บันทึกค้าไม่สำเร็จโปรดลองใหม่อีกครั้ง</small>,
                            icon: 'error'
                        })
                    }
                })
            }else{
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>อัพโหลดรูปภาพไม่สำเร็จ</small>,
                    icon: 'error'
                })
            }
        })
    }else{
        formData.append('fileImage' , false)
        Axios.post('https://berriesblossoms.com:3000/update/product' , formData).then(response => {
            const res = response.data
            if(res.success){
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>บันทึกค้าสำเร็จ</small>,
                    icon: 'success'
                }).then(() => {
                    window.location.reload(false);
                })
            }else{
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>{res.msg}</small>,
                    icon: 'error'
                })
            }
        })
    }
}

function AdminProduct() {
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(window.location.search);
    const taste = queryParams.get('taste');
    const size = queryParams.get('size');
    const group = queryParams.get('group');
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentSize, setCurrentSize] = useState(size)
    const [selectProductId, setSelectProductId] = useState(null);
    const [selectProduct, setSelectProduct] = useState([]);
    const [show, setShow] = useState(false);

    const handleChangeEdit = (e) => {
        const name = e.target.name
        const value = e.target.value
        setSelectProduct(values => ({...values , [name]: value}))
    }

    const [selectedFile, setSelectedFile] = useState();
    const changeFileHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};

    const handleClose = () => {
        setShow(false) 
        setSelectProduct([])
    };
    const handleShow = () => setShow(true);

    useEffect(() => { // โหลดสินค้าครั้งแรก
        async function fetchData() {
            setLoading(true);
            if(taste == 'special_event' || taste == 'yusu_premium' || taste == 'fruity_berries'){
                console.log(taste)
                Axios.post('https://berriesblossoms.com:3000/get/product', { taste: taste, size: currentSize  }).then(response => {
                    const res = response.data
                    setProducts(res.products)
                })
            }else{
                Axios.post('https://berriesblossoms.com:3000/get/product/simple', { taste: taste, size: currentSize , group:group}).then(response => {
                    const res = response.data
                    setProducts(res.products)
                })
            }
            setLoading(false);
        }
        fetchData()
    }, [currentSize])

    useEffect(() => { // โหลดสินค้าตอนที่กดแก้ไข
        async function fetchData() {
            setLoading(true);
            if(selectProductId != null){
                 Axios.post('https://berriesblossoms.com:3000/get/product_id/', { id:selectProductId }).then(response => {
                    setSelectProduct(response.data.product)
                    handleShow()
                })
            }
            setLoading(false);
        }
        fetchData()
    }, [selectProductId])

    const selectSize = (e) => {
        // navigate('/products?taste='+taste+'&size='+e)
        if(taste == 'original' || taste == 'choco_berries' || taste == 'yusu_blossoms'){
            if(e == 'simple'){
                window.location.href = '/products?taste='+taste+'&size='+e+'&group=rose'
            }else{
                window.location.href = '/products?taste='+taste+'&size='+e
            }
        }else{
            window.location.href = '/products?taste='+taste+'&size='+e
        }
       
        
    }

    const handleEdit = (e) => {
        setSelectProductId(e)
    }

    var selectGroup
    if(currentSize == 'simple'){
        selectGroup = <div className='form-group'>
                        <label>หมวดหมู่สินค้า</label>
                        <select className='form-control' defaultValue={selectProduct.group} onChange={handleChangeEdit} name='group'>
                            <option value="rose" >กุหลาบ / Rose</option>
                            <option value="carnation" >คาร์เนชั่น / Carnation</option>
                            <option value="groups" >ร้านค้าออกแบบให้ / Designer's Choice</option>
                            <option value="others" >ดอกไม้อื่นๆ / Others</option>
                            <option value="dahlia" >ดาห์เลีย / Dahlia</option>
                            <option value="water_lily" >บัวสาย / Water Lily</option>
                            <option value="lotus" >บัวหลวง / Lotus</option>
                            <option value="peony" >โบตั๋น / Peony</option>
                        </select>
                    </div>
    }
    
    let typeNameArr = {original:"Original" , choco_berries:'Choco Berries' , yusu_blossoms: 'Yusu Blossoms' , yusu_premium:'Yusu Premium', fruity_berries:'Fruity Berries' , special:'Special'}
    let typeName = <span>{typeNameArr[taste]}</span>
    let selectSizeGroupEl
    if(taste != 'special_event'){
        selectSizeGroupEl = <div className='select-size-group'>
                            <div className='select-size-item' >
                                <div className={`select-size ${size == 'simple' ? 'active' : ''}`} data-size="simple" onClick={() => selectSize('simple')}>
                                    {size == 'simple' ? <i className='fad fa-check-circle me-2'></i> : ''} Simple
                                </div>
                            </div>
                            <div className='select-size-item' >
                                <div className={`select-size ${size == 'merry' ? 'active' : ''}`} data-size="merry"  onClick={() => selectSize('merry')}>
                                    {size == 'merry' ? <i className='fad fa-check-circle me-2'></i> : ''} Merry
                                </div>
                            </div>
                            <div className='select-size-item'>
                                <div className={`select-size ${size == 'love' ? 'active' : ''}`} data-size="love"  onClick={() => selectSize('love')}>
                                    {size == 'meloverry' ? <i className='fad fa-check-circle me-2'></i> : ''} Love
                                </div>
                            </div>
                            <div className='select-size-item' >
                                <div className={`select-size ${size == 'extralove' ? 'active' : ''}`} data-size="extralove"  onClick={() => selectSize('extralove')}>
                                    {size == 'extralove' ? <i className='fad fa-check-circle me-2'></i> : ''} Extra Love
                                </div>
                            </div>
                            <div className='select-size-item' >
                                <div className={`select-size ${size == 'forever' ? 'active' : ''}`} data-size="forever"  onClick={() => selectSize('forever')}>
                                    {size == 'forever' ? <i className='fad fa-check-circle me-2'></i> : ''} Forever
                                </div>
                            </div>
                        </div>
    }else{
        selectSizeGroupEl = <div className='select-size-group'>
                            <div className='select-size-item' >
                                <div className={`select-size ${size == 'moon_festival' ? 'active' : ''}`} data-size="moon_festival" onClick={() => selectSize('moon_festival')}>
                                    {size == 'moon_festival' ? <i className='fad fa-check-circle me-2'></i> : ''} Moon Festival
                                </div>
                            </div>
                            <div className='select-size-item' >
                                <div className={`select-size ${size == 'vegetarien' ? 'active' : ''}`} data-size="vegetarien"  onClick={() => selectSize('vegetarien')}>
                                    {size == 'vegetarien' ? <i className='fad fa-check-circle me-2'></i> : ''} Vegetarien Festival
                                </div>
                            </div>
                            <div className='select-size-item'>
                                <div className={`select-size ${size == 'mother_day' ? 'active' : ''}`} data-size="mother_day"  onClick={() => selectSize('mother_day')}>
                                    {size == 'mother_day' ? <i className='fad fa-check-circle me-2'></i> : ''} Mother's Day
                                </div>
                            </div>
                            <div className='select-size-item' >
                                <div className={`select-size ${size == 'special_size' ? 'active' : ''}`} data-size="special_size"  onClick={() => selectSize('special_size')}>
                                    {size == 'special_size' ? <i className='fad fa-check-circle me-2'></i> : ''} Special Size
                                </div>
                            </div>
                            <div className='select-size-item' >
                                <div className={`select-size ${size == 'happy_berries' ? 'active' : ''}`} data-size="happy_berries"  onClick={() => selectSize('happy_berries')}>
                                    {size == 'happy_berries' ? <i className='fad fa-check-circle me-2'></i> : ''} Happy Berries
                                </div>
                            </div>
                            <div className='select-size-item' >
                                <div className={`select-size ${size == 'chinese_new_year' ? 'active' : ''}`} data-size="chinese_new_year"  onClick={() => selectSize('chinese_new_year')}>
                                    {size == 'chinese_new_year' ? <i className='fad fa-check-circle me-2'></i> : ''} Chinese New Year
                                </div>
                            </div>
                        </div>
    }
    const selecteRef = React.createRef()
    const handleChangeGroup = (e) => {
        window.location.href = '/products?taste='+taste+'&size='+size+'&group='+selecteRef.current.value
    }
    return (
        <div>
            <Header page={'products'} />
            <div className="container mt-5">
                <div className="d-flex">
                    <h4>จัดการสินค้า {typeName}</h4>
                    <button className="ms-auto btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModal"><i className="fas fa-plus"></i> เพิ่มสินค้า</button>
                </div>
                <div className="row">
                    <div className="col-md-10">
                        {selectSizeGroupEl}
                    </div>
                </div>
                <div className="row mt-5" id="products">
                    {group != null ? 
                    <div className="col-md-2">
                        <label>เลือกประเภทสินค้า</label>
                        <select className="form-control" ref={selecteRef} onChange={handleChangeGroup} defaultValue={group}>
                            <option value="rose" >กุหลาบ / Rose</option>
                            <option value="carnation" >คาร์เนชั่น / Carnation</option>
                            <option value="groups" >ร้านค้าออกแบบให้ / Designer's Choice</option>
                            <option value="others" >ดอกไม้อื่นๆ / Others</option>
                            <option value="dahlia" >ดาห์เลีย / Dahlia</option>
                            <option value="water_lily" >บัวสาย / Water Lily</option>
                            <option value="lotus" >บัวหลวง / Lotus</option>
                            <option value="peony" >โบตั๋น / Peony</option>
                        </select>
                    </div>
                    : null}
                    <div className="w-100 mb-3"></div>
                    {products.length ? products.map((product  , index) => (
                        <ProductAdminCard product={product} key={index} handleEdit={handleEdit} />
                    )) : <div className="col-12 mt-5"><h1 className="text-center text-muted">ขออภัย, ไม่มีข้อมูล</h1></div>}
                </div>
            </div>
            <ProductForm currentdata={currentSize} />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>แก้ไขสินค้า</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleEditSubmit}>
                    <Modal.Body>
                    <div className="form-group-file mb-3">
                        <label>รูปสินค้า</label>
                        <br></br>
                        <input type="file" name="cover_image" id="fileImage" onChange={changeFileHandler} className="form-control-file mt-2"  />
                    </div>
                    {selectGroup}
                    <div className="form-group">
                        <label>ชื่อสินค้า</label>
                        <input type="text" value={selectProduct ? selectProduct.name : ""} onChange={handleChangeEdit} name="name" className="form-control"  />
                        <input type="hidden" value={selectProduct ? selectProduct.cover_image : ""}  name="old_image" className="form-control"  />
                    </div>
                    <div className="form-group">
                        <label>ราคาสินค้า <span className="text-danger">*</span></label>
                        <input type="number" value={selectProduct ? selectProduct.price : ""} onChange={handleChangeEdit} name="price" className="form-control"  required/>
                        <input type="hidden" value={selectProduct ? selectProduct._id : ""} name="id" className="form-control"  required/>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            ยกเลิก
                        </Button>
                        <Button type="submit" variant="primary">
                            บันทึก
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    )
}

export default AdminProduct