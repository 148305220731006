import Header from "../components/Header"
import React , { useState , useEffect } from "react";
import { Link , useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import Axios from 'axios'
import DataTable from "react-data-table-component";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";

function Status(props){
    let status
    if(props.status == 0){
        status = 'รอดำเนินการ'
    }else if(props.status == 1){
        status = 'จัดส่งแล้ว'
    }

    return (
        <span>
            {status}
        </span>
    )
}

function ViewButton(props){
    const navigate = useNavigate()
    function viewDetail(){
        navigate('/myorder/'+props.id)
    }
    return (
        <button type="button" onClick={viewDetail} className="btn btn-sm btn-info text-white">ดูรายละเอียด</button>
    )
}

function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }
  
  function toPages(pages) {
    const results = [];
  
    for (let i = 1; i < pages; i++) {
      results.push(i);
    }
  
    return results;
  }
 
  const columns = [
    {
      name: <h6>เลขคำสั่งซื้อ</h6>,
      selector: (row) => <h6 className="text-danger">{row.order_id}</h6>,
      sortable: true
    },
    {
      name: <h6>วันที่สั่งซื้อ</h6>,
      selector: (row) => <h6>{new Intl.DateTimeFormat('th-TH', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(row.created_at)}</h6>,
      sortable: true
    },
    {
      name: <h6>สถานะคำสั่งซื้อ</h6>,
      selector: (row) =>  <h6><Status status={row.status}/></h6>,
      sortable: true
    },
    {
      name: "",
      selector: (row) =>  <ViewButton id={row._id}/>,
      sortable: false
    }
  ];
  
  // RDT exposes the following internal pagination properties
  const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage
  }) => {
    const handleBackButtonClick = () => {
      onChangePage(currentPage - 1);
    };
  
    const handleNextButtonClick = () => {
      onChangePage(currentPage + 1);
    };
  
    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };
  
    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;
  
    return (
      <nav>
        <ul className="pagination justify-content-center">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page">
              กลับ
            </button>
          </li>
          {pageItems.map((page) => {
            const className =
              page === currentPage ? "page-item active" : "page-item";
  
            return (
              <li key={page} className={className}>
                <button
                  className="page-link"
                  onClick={handlePageNumber}
                  value={page}
                >
                  {page}
                </button>
              </li>
            );
          })}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              ต่อไป
            </button>
          </li>
        </ul>
      </nav>
    );
  };
  
  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

function MyOrders(){
    const [orders , setOrders]  = useState([])
    const [loading , setLoading]  = useState(false)
    useEffect(() => {
        async function loadOrders(){
            setLoading(true)
                const dataObj = {token: localStorage.getItem('token')}
                Axios.post('https://berriesblossoms.com:3000/get/user' , dataObj).then(res => {
                    if(res.data.status == 'success'){
                        const query = {user_id:res.data.user._id}
                        Axios.post('https://berriesblossoms.com:3000/get/orders' , query).then(response => {
                            const res = response.data
                            setOrders(res.orders)
                        })
                    }
                })
            setLoading(false)
        }
        loadOrders()
    }, [])
    
    return(
        <div className="myOrdersPage">
            <Header/>
            <div className="container mt-5" >
                <h4 className="text-center">ประวัติการสั่งซื้อ</h4>
                <div className="card mt-3">
                    <DataTable
                    columns={columns}
                    data={orders}
                    defaultSortFieldID={1}
                    pagination
                    paginationComponent={BootyPagination}
                    // selectableRows
                    // selectableRowsComponent={BootyCheckbox}
                    />
                </div>
            </div>
        </div>
    )
}

export default MyOrders