import React , { useState,  useEffect } from "react";
import Axios from 'axios'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link, Navigate, useNavigate } from 'react-router-dom'
const Swal = withReactContent(swal)

export const AuthContext = React.createContext()

export const AuthProvider = ({children}) => {
    const navigate = useNavigate()
    const [currentUser , setCurrentUser] = useState(null)
    const dataObj = {token: localStorage.getItem('token')}
    useEffect(() => {
        Axios.post('https://berriesblossoms.com:3000/get/user' , dataObj).then(res => {
            var res = res.data
            if(res.status == 'success'){
                setCurrentUser(res.user)
            }else{
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>{res.msg}</small>,
                    icon: 'error'
                }).then(() => {
                    navigate('/login')
                })
            }
        })
        
    }, [])

    return (
        <AuthContext.Provider value={{currentUser}}>
            {children}
        </AuthContext.Provider>
    )
}