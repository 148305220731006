import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link , Navigate , useNavigate } from 'react-router-dom'
import Axios from 'axios';
import swal from "sweetalert2"
import withReactContent from 'sweetalert2-react-content';
import { useState , useEffect } from 'react';
const Swal = withReactContent(swal)

const RegisterPage = () =>{
        
        const [passwordValidate , setPasswordValidate] = useState(false)
        const navigate = useNavigate()
        const dataObj = {token: localStorage.getItem('token')}
        useEffect(() => {
            Axios.post('https://berriesblossoms.com:3000/get/user' , dataObj).then(res => {
                var res = res.data
                if(res.status == 'success'){
                    navigate('/')
                }
            })
        })
        
        const [inputs , setInputs] = useState({})

        const handleChange = (e) => {
            const name = e.target.name
            const value = e.target.value
            setInputs(values => ({...values , [name]:value}))
        }

        
        const handleSubmit = (e) => {
            e.preventDefault()
            try {
                const data = {
                    phone:inputs.phone,
                    fullname: inputs.fullname,
                }
                Axios.post("https://berriesblossoms.com:3000/register", data ).then(res => {
                    if(res.data.created){
                        Swal.fire({
                            title: <strong>สมัครสมาชิกสำเร็จ</strong>,
                            html: <small>ขอบคุณค่ะ</small>,
                            icon: 'success'
                        }).then(() => {
                            navigate('/login')
                        })
                    }else{
                        Swal.fire({
                            title: <strong>สมัครสมาชิกไม่สำเร็จ</strong>,
                            html: <small>{res.data.msg}</small>,
                            icon: 'error'
                        })
                    } 
                });
            } catch (error) {
                // console.log()
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>{error}</small>,
                    icon: 'error'
                })
            }
        } 



     
        return (
            <div>
                <Header/>
                <div className='loginBg'>
                    <div className='container py-5'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-4'>
                                <div className='loginSection'>
                                    <div className='whiteCard'>
                                        <h4 className='text-center mb-4'>ยินดีต้อนรับ</h4>
                                        <form onSubmit={handleSubmit}>
                                            <div className='form-group'>
                                                <label><i className='far fa-user'></i> ชื่อ - นามสกุล</label>
                                                <input type="text" name='fullname' value={inputs.fullname || ""} onChange={handleChange} id="fullname" className="form-control" required/>
                                            </div>
                                            <div className='form-group'>
                                                <label><i className='far fa-phone'></i> เบอร์โทรศัพท์</label>
                                                <input type="text" name='phone' value={inputs.phone || ""} onChange={handleChange} id="phone" className="form-control"  required/>
                                            </div>
                                            {/* <div className='row mb-3 mb-3'>
                                                <div className='col-6'>
                                                    <a target="_blank" href="https://www.facebook.com/berries.blossoms" type='button' className='add-friend facebook'><i className='fab fa-facebook'></i><span> FACEBOOK</span><br></br>Add Friend</a>
                                                </div>
                                                <div className='col-6'>
                                                    <a target="_blank" href="https://line.me/R/ti/p/%40berries_blossoms"  type='button' className='add-friend line'><i className='fab fa-line'></i><span>  LINE</span><br></br>Add Friend</a>
                                                </div>
                                            </div> */}
                                            <button type='submit' className='submit-btn'><i className='fad fa-user-plus'></i>  Complete</button>
                                        </form>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
}

export default RegisterPage