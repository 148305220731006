import React from 'react';
import logo from '../assets/imgs/highres_logo.png';
// import './App.css';
import '../assets/css/main.css';
import '../assets/css/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'
import MenuBar from './MenuBar';
import { render } from '@testing-library/react';
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const Swal = withReactContent(swal)

function openBar(){
  const bar = document.getElementById('menu-bar')
  bar.style.left = '0'
  bar.style.zIndex = '999'
}

function Header(props) {
 
  let newObject = localStorage.getItem("cart");
  let cartCount = newObject ? JSON.parse(newObject).length : 0
  const [carts , setCarts] = useState(cartCount.toString())
  const [loading , setLoading] = useState(false)

  useEffect(() => {
    async function loadCart(){
      let newObject = localStorage.getItem("cart");
      setCarts( newObject ? JSON.parse(newObject).length : 0)
    }
    loadCart()
  } , [carts])

  if(props.totalCart != carts){
    setCarts(props.totalCart)
  }

  const UserLogout = (e) => {
      Swal.fire({
          title: 'คุณต้องการจะออกจากระบบ',
          icon: 'warning',
          showDenyButton: true,
          confirmButtonText: 'ออกจากระบบ',
          denyButtonText: `ยกเลิก`,
        }).then((result) => {
          if (result.isConfirmed) {
            Cookies.remove('user_fullname')
            localStorage.removeItem("token")
            Swal.fire('ออกจากระบบแล้ว', '', 'success').then(() => {
              window.location.replace('/login')
            })
          }
        })
    }

  // render () {
      let buttonUser
      const user_fullname = Cookies.get('user_fullname')
      
      if (user_fullname != undefined) {
        buttonUser =  <div className="dropdown">
                        <button className="header-link dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className='fas fa-user'></i>  {user_fullname}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><Link className="dropdown-item" to="/account?tab=setting"><i className="fas fa-user-cog"></i> ตั้งค่าบัญชี</Link></li>
                          <li><Link className="dropdown-item" to="/myorders"><i className='fa fa-history'></i> ประวัติการสั่งซื้อ</Link></li>
                          <li><button type='button' className="dropdown-item" onClick={UserLogout} ><i className='fas fa-door-open'></i> ออกจากระบบ</button></li>
                        </ul>
                      </div>
      } else {
        buttonUser = <Link className="header-link "  to="/login"><i className="fad fa-door-open"></i>เข้าสู่ระบบ</Link>
      }
     
    
      return (
          <div className='shadow-header'>
            <div className='container'>
              <header className="main-header position-relative">
                <div className='menu-top'>
                  <span><a className='link-normal' href="tel:0859179045"><i className='fas fa-phone mr-1'></i> 085 917 9045</a></span>  
                  <span className='ms-3'><a className='link-normal' href="https://www.facebook.com/berries.blossoms"><i className='fab fa-facebook mr-1'></i> Berries n' Blossoms</a></span>  
                </div>
                <div className='menu-flex'>
                  <img src={logo} className="logoHeader"/>
                  <div className='header-menu'>
                      <Link className="header-link active sm-d-none"  to="/home"><i className='fad fa-home'></i> หน้าแรก</Link>
                      <Link className="header-link sm-d-none"  to="/select_taste"><i className="fad fa-hat-chef"></i> สั่งสินค้า/เลือกรสชาติ</Link>
                      <a className="header-link sm-d-none"  href="/contactus" ><i className="fad fa-phone"></i> ติดต่อเรา</a>
                      <a className="header-link sm-d-none" href="#">|</a>
                      {buttonUser}
                      <Link className="header-link position-relative"  to="/cart"><i className="fad fa-shopping-cart"></i><span className='cart-header'>{cartCount}</span></Link>
                      <a className="header-link sm-d-block"  href="#" onClick={openBar} ><i className="fas fa-bars"></i></a>
                  </div>
                </div>
              </header>
              <MenuBar/>
            </div>
          </div>
      )
  // }
}


export default Header;
