import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import Header from '../components/Header';
import Banner from '../components/Banner';
import ProductCard from '../components/ProductCard';
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';
import SelectTaste from '../components/SelectTaste';


const products = [
    {
      'img': 'images/pd-image.jpg',
      'title': 'กลุ่มดอกไม้',
    },
    {
      'img': 'images/pd-image.jpg',
      'title': 'กลุ่มดอกไม้',
    },
    {
      'img': 'images/pd-image.jpg',
      'title': 'กลุ่มดอกไม้',
    },
    {
      'img': 'images/pd-image.jpg',
      'title': 'กลุ่มดอกไม้',
    },
    {
      'img': 'images/pd-image.jpg',
      'title': 'กลุ่มดอกไม้',
    },
    {
      'img': 'images/pd-image.jpg',
      'title': 'กลุ่มดอกไม้',
    },
    {
      'img': 'images/pd-image.jpg',
      'title': 'กลุ่มดอกไม้',
    },
    {
      'img': 'images/pd-image.jpg',
      'title': 'กลุ่มดอกไม้',
    },
  ]
  
  const allProducts = []
  for (let i = 0; i < 12; i++) {
    allProducts.push({'img': 'https://dummyimage.com/400x400/e3e3e3/fff',
    'title': 'กลุ่มดอกไม้',})
  }

  
function SelectTastePage(){
    const productElements = products.map((product , index) => {
        return <div className='slide-item mb-3'> <ProductCard key={index}  theme="gray" product={product} /></div>
    })

    const allProductElements = allProducts.map((product , index) => {
        return <div className='col-6 product-white col-md-3 mb-3'> <ProductCard key={index} theme="white" product={product} /></div>
    })
    return (
    <div className='SelectTastePage'>
        <Header/>
        <div className='my-5'>
            <SelectTaste/>
        </div>
        <div className='margin-footer'></div>
      <Footer/>
    </div>
    )
}

export default SelectTastePage