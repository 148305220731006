import React, { useState , useEffect } from "react"
import Axios from 'axios'
import { Link, useParams } from "react-router-dom"
import withReactContent from 'sweetalert2-react-content'
import swal from "sweetalert2";
const Swal = withReactContent(swal)

function NormalPage(props){
    
    // console.log()
    const {taste , size} = useParams()
    const [products , setProducts]  = useState([])
    const [loading, setLoading] = useState(false)
    const addcart = (product) => {
        const dataObj = {token: localStorage.getItem('token')}
        Axios.post('https://berriesblossoms.com:3000/get/user' , dataObj).then(res => {
            var res = res.data
            if(res.status != 'success'){
                Swal.fire({
                    title: <h4>กรุณาเข้าสู่ระบบก่อนสั่งซื้อ</h4>,
                    html: "",
                    icon: "error",
                    confirmButtonText: 'เข้าสู่ระบบ',
                }).then(() => {
                    window.location.href = '/login'
                })
                return false
            }
            const obj = {name:product.name , price:product.price , size:product.size, taste:product.taste ,  cover_image: product.cover_image , amount:1}
            let currentCart = localStorage.getItem("cart");
            currentCart = JSON.parse(currentCart)
            if(currentCart == null){
                currentCart = [obj]
            }else{
                currentCart.push(obj)
            }
            localStorage.setItem('cart' , JSON.stringify(currentCart))
            let newCart = localStorage.getItem("cart");
            props.setCart(JSON.parse(newCart).length)
            Swal.fire({
                title: <strong>เพิ่มลงตะกร้าแล้ว</strong>,
                html: "",
                icon: "success"
            })
        })
    }

    
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

     

    const productElements = products.map((product , index) => {
        return <div className="normal-product col-6 col-md-3 mb-3" key={index}>
                    <div className="product-card">
                        <div className="image hover" onClick={() => props.handleShow(`https://berriesblossoms.com:3001/${product.cover_image}`)} style={{ backgroundImage: `url(https://berriesblossoms.com:3001/${product.cover_image})` }}></div>
                        {/* <img src={image} className="w-100 mb-3"/> */}
                        <div className='d-flex'>
                            <h6 className='product-name mb-0'>{product.name}</h6>
                            <h6 className='product-price ms-auto  mb-0'>{numberWithCommas(product.price)} THB</h6>
                        </div>
                        <div className="product-card-action">
                            <div className="amount-action">
                                <button className="addcart-btn" onClick={() => addcart(product)}><i className="fas fa-cart-arrow-down"></i> เพิ่มลงตะกร้า</button>
                            </div>
                        </div>
                    </div> 
                </div>
    })

    // var 
    useEffect(() => {
        async function fetchData(){
            setLoading(true)
            Axios.post('https://berriesblossoms.com:3000/get/product', {taste , size }).then(response => {
                const res = response.data
                setProducts(res.products)
            })
            setLoading(false)
        }

        fetchData()
    }, [])


    return <div>
            <h3 className="text-center">สินค้าทั้งหมด</h3>
            <div className="row mt-5">
                {productElements}
            </div>
        </div>
}

export default NormalPage