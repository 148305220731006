import logo from '../assets/imgs/highres_logo.png';
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie'

function closeBar(){
    const bar = document.getElementById('menu-bar')
    bar.style.left = '-100%'
    bar.style.zIndex = '-1'
}

function MenuBar(){
    const user_fullname = Cookies.get('user_fullname')
    return (
        <div className="menu-bar" id="menu-bar">
            <div className='menubar-header'>
                <h4>Menu</h4>
                <h4 className='close-bar' onClick={closeBar}><i className='far fa-times'></i></h4>
            </div>
            <ul>
                <li className='active'>
                    <Link to="/" className><i className='fad fa-home'></i> หน้าแรก</Link>
                </li>
                <li>
                    <Link to="/select_taste" className><i className="fad fa-hat-chef"></i> สั่งสินค้า/เลือกรสชาติ</Link>
                </li>
                {user_fullname ? '' : <li>
                                        <Link className><i className="fad fa-door-open"></i> เข้าสู่ระบบ/สมัครสมาชิก</Link>
                                    </li>}
                <li>
                    <Link to="/contactus" className><i className="fad fa-user"></i> ติดต่อเรา</Link>
                </li>
            </ul>
        </div>
    )
}

export default MenuBar