import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Axios from 'axios'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'js-cookie'
import React , { useState , useContext , useEffect } from 'react';
import { AuthContext } from '../components/Auth'
const Swal = withReactContent(swal)



function AccountPage(){
        const queryParams = new URLSearchParams(window.location.search);
        const tab = queryParams.get('tab');
        const phone = React.createRef()
        const fullname = React.createRef()
        const [uEmail , setEmail] = useState("")
        const dataObj = {token: localStorage.getItem('token')}
        useEffect(() => {
            Axios.post('https://berriesblossoms.com:3000/get/user' , dataObj).then(res => {
                var res = res.data
                if(res.status != 'success'){
                    navigate('/')
                }
                if(tab == 'setting'){
                    phone.current.value = res.user.phone
                    fullname.current.value = res.user.fullname
                }
            })
        } , [])
        

        const navigate = useNavigate()

        const handleSubmit = (event) => {
            event.preventDefault();
            let url = 'https://berriesblossoms.com:3000/update/user'
            let dataObj = {token: localStorage.getItem('token') , phone:phone.current.value, fullname:fullname.current.value}
            Axios.post(url , dataObj).then(res => {
                var res = res.data
                if(res.success){
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน</strong>,
                        html: <small>อัพเดตข้อมูลสำเร็จ</small>,
                        icon: 'success'
                    }).then(() => {
                        window.location.reload(false);
                    })
                }else{
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน</strong>,
                        html: <small>{res.msg}</small>,
                        icon: 'error'
                    })
                }
            })
        }


        const handleRedirect = (e) => {
            const redirect = e.target.dataset.redirect
            window.location.href = redirect
        }
        return (
            <div>
                <Header/>
                <div className='loginBg'>
                    <div className='container py-5'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-4'>
                                <div className='loginSection'>
                                    <div className='whiteCard'>
                                        <h4 className='text-center mb-4'>บัญชี</h4>
                                        <form id="loginForm" onSubmit={handleSubmit}>
                                            <input type="hidden" id="form_type" name='form_type' value="login" />
                                            <div className='form-group'>
                                                <label><i className='far fa-user'></i> ชื่อ - นามสกุล</label>
                                                <input type="text" name='fullname' ref={fullname}  className="form-control" required/>
                                            </div>
                                            <div className='form-group'>
                                                <label><i className='far fa-phone'></i> เบอร์โทรศัพท์</label>
                                                <input type="text" name='phone' ref={phone}  className="form-control" required/>
                                            </div>                                    
                                            <button type='submit'  className='submit-btn'><i className='fad fa-save'></i> บันทึก</button>
                                        </form>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
}

export default AccountPage