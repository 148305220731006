import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Products from '../Pages/Products'
const Swal = withReactContent(swal)

function deleteProduct(id){
    Swal.fire({
        title: 'คุณต้องการที่จะลบสินค้า ?',
        showDenyButton: true,
        confirmButtonText: 'ยืนยัน',
        denyButtonText: `ยกเลิก`,
      }).then((result) => {
        if (result.isConfirmed) {
            Axios.post('https://berriesblossoms.com:3000/delete/product' , {id:id} ).then(response => {
                if(response.data.success){
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน</strong>,
                        html: <small>ลบสินค้าสำเร็จ!!</small>,
                        icon: 'success'
                    }).then(() => {
                        window.location.reload(false);
                    })
                }else{
                    Swal.fire('บันทึกไม่สำเร็จ ', 'โปรดลองใหม่อีกครั้ง', 'error')
                }
            })
        }
    })
}

function ProductAdminCard(props){

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const {product , handleEdit} = props
    const image = 'https://berriesblossoms.com:3001/'+ product.cover_image 

    const groupsName = {rose: 'กุหลาบ / Rose' ,
                        carnation:'คาร์เนชั่น / Carnation', 
                        groups:'ดอกไม้กลุ่ม / Groups', 
                        others:'ดอกไม้อื่นๆ / Others', 
                        dahlia:'ดาห์เลีย / Dahlia', 
                        water_lily:'บัวสาย / Water Lily', 
                        lotus:'บัวหลวง / Lotus', 
                        peony:'โบตั๋น / Peony'}
    return (
        <div className="col-6 col-md-2 mb-3">
            <div className="pd-admin-card">
                <img src={image} className="w-100 rounded" />
                {/* <div className="pd-admin-image" style={divStyle}></div> */}
                <div className="d-flex mt-2">
                    <span className="pd-admin-name">{product.name ? product.name : ""}</span>
                    <button className="ms-auto delete-pd-admin" onClick={() => deleteProduct(product._id)}><i className="fas fa-trash"></i></button>
                    <button className="edit-pd-admin ms-2" onClick={() => handleEdit(product._id)}><i className="fas fa-edit"></i></button>
                </div>
                <span><strong>{numberWithCommas(product.price)} บาท</strong></span>
            </div>
        </div>
    )
}

export default ProductAdminCard;