import Axios from 'axios'
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export default function CheckLading(){
    const navigate = useNavigate();
    
    useEffect(() => {
        Axios.get('https://berriesblossoms.com:3000/get/landing', {}).then(res => {
            if(res.data.landing_status == 1){
                navigate('/welcome')
            }else{
                navigate('/home')
            }
        })
    })
    return (
        <div>
            
        </div>
    )
}