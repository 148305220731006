import React , { useState , useEffect } from "react";
import ReactDOM from "react-dom";
import { Link , useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Axios from 'axios'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Swal = withReactContent(swal)



function Cart() {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');

        const copyCode = (e) => {
            var copyText = code
            navigator.clipboard.writeText(copyText);

            Swal.fire({
                title: <h4>แจ้งเตือน</h4>,
                html: <h5>คัดลอกสำเร็จ</h5>,
                icon: "success"
            })
            
        }
        return(
            <div>
                <Header/>
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="thankyou-card">
                                <h1 className="thankyou-title">THANK YOU <br></br> <span>for your order</span></h1>
                                <h5 className="my-4 text-center">ขั้นตอนต่อไป</h5>
                                <div className="row">
                                    <div className="col-4 text-center">
                                        <div className="step">1</div>
                                        <p>เช็คค่าจัดส่ง<br></br>ตามระยะทาง</p>
                                    </div>
                                    <div className="col-4 text-center">
                                        <div className="step">2</div>
                                        <p>เช็คคิวงาน&<br></br>การนัดหมาย</p>
                                    </div>
                                    <div className="col-4 text-center">
                                        <div className="step">3</div>
                                        <p>แจ้งสรุป<br></br>ทาง Inbox</p>
                                    </div>
                                </div>
                                <h5 className="my-4 text-center">ค่าจัดส่ง</h5>
                                <p><i className="fas fa-info-circle"></i> รับสินค้าที่ร้าน ไม่มีค่าจัดส่ง</p>
                                <p><i className="fas fa-info-circle"></i> กรุงเทพและใกล้เคียง By <img width="75" src="../images/Lalamove-Logo.png"/> </p>
                                <p><i className="fas fa-info-circle"></i> ต่างจังหวัดใช้บริการขนส่งควบคุมอุณหภูมิ</p>
                                <img width="200" src="../images/logo-delivery.png" />
                                <div className='row mb-3'>
                                <div className="col-12 mt-4">
                                    <div className="order-code-card text-center text-white" onClick={copyCode}>
                                        <h6>หมายเลขคำสั่งซื้อของคุณ</h6>
                                        <h4 className="mb-0" onClick={copyCode}>{code}</h4>
                                    </div>
                                    <h6 className="my-3 text-center">กรุณา<span className="text-danger hover" onClick={copyCode}>คัดลอก<i className="fas fa-copy"></i></span>หมายเลขคำสั่งซื้อของคุณ แล้วส่งไปยัง</h6>
                                </div>
                                    <div className='col-6'>
                                        <a target="_blank" href="https://www.facebook.com/berries.blossoms" type='button' className='add-friend facebook'><i className='fab fa-facebook'></i><span> FACEBOOK</span><br></br>Add Friend</a>
                                    </div>
                                    <div className='col-6'>
                                        <a target="_blank" href="https://line.me/R/ti/p/%40berries_blossoms"  type='button' className='add-friend line'><i className='fab fa-line'></i><span>  LINE</span><br></br>Add Friend</a>
                                    </div>
                                </div>
                                <Link className="complete" to="/">Complete</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default Cart