
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import HomePage from './Pages/HomePage';
import SelectTastePage from './Pages/SelectTastePage';
import SelectSizePage from './Pages/SelectSizePage';
import SelectModelPage from './Pages/SelectModelPage';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import LoginPage from './Pages/LoginPage';
import AccountPage from './Pages/AccountPage';
import RegisterPage from './Pages/RegisterPage';
import Cart from './Pages/Cart';
import Admin from './Admin/Pages/Home';
import AdminLogin from './Admin/Pages/Login';
import AdminProducts from './Admin/Pages/AdminProduct';
import ForgotPassword from './Pages/ForgotPassword'
import ResetPassword from './Pages/ResetPassword'
import MyOrders from './Pages/MyOrders'
import OrderDetail from './Pages/OrderDetail'
import AdminOrderDetail from './Admin/Pages/OrderDetail'
import Video from './Admin/Pages/Video'
import Banners from './Admin/Pages/Banners'
import AddMultiProduct from './Admin/Pages/AddMultiProduct'
import ContactUsPage from './Pages/ContactUsPage';
import Thankyou from './Pages/Thankyou';
import AdminLanding from './Admin/Pages/Landing'
import CheckLanding from './Pages/CheckLanding'
import Welcome from './Pages/Welcome'
function App() {
  
  return (
    <div id="main-body">
      <Routes basename="/app">
        <Route path="" exact element={<CheckLanding/>} />
        <Route path="/" exact element={<CheckLanding/>} />
        <Route path="/home" exact element={<HomePage/>} />
        <Route path="/Welcome" exact element={<Welcome/>} />
        <Route path="/login" exact element={<LoginPage/>} />
        <Route path="/forgot_password" exact element={<ForgotPassword/>} />
        <Route path="/reset_password" exact element={<ResetPassword/>} />
        <Route path="/register" exact element={<RegisterPage/>} />
        <Route path="/account" exact element={<AccountPage/>} />
        <Route path="/select_taste" exact element={<SelectTastePage/>} />
        <Route path="/select_size/:taste" exact element={<SelectSizePage/>} />
        <Route path="/select_model/:taste/:size" exact element={<SelectModelPage/>} />
        <Route path="/cart" exact element={<Cart/>} />
        <Route path="/thankyou" exact element={<Thankyou/>} />
        <Route path="/contactus" exact element={<ContactUsPage/>} />
        <Route path="/myorders" exact element={<MyOrders/>} />
        <Route path="/myorder/:id" exact element={<OrderDetail/>} />
        <Route path="/admin" exact element={<Admin/>} />
        <Route path="/admin/banners" exact element={<Banners/>} />
        <Route path="/admin/addmulti/product" exact element={<AddMultiProduct/>} />
        <Route path="/admin/video" exact element={<Video/>} />
        <Route path="/order/:id" exact element={<AdminOrderDetail/>} />
        <Route path="/admin/login" exact element={<AdminLogin/>} />
        <Route path="/admin/landing" exact element={<AdminLanding/>} />
        <Route path="/products" exact element={<AdminProducts/>} />
      </Routes>
    </div>
  );
}


export default App;
