import React, { useState , useEffect } from "react"
import ProductCard from "./ProductCard"
import ProductSlide from "./ProductSlide"
import Slick from "../Pages/Slick"
import Axios from 'axios'
import { Link, useParams } from "react-router-dom"
import withReactContent from 'sweetalert2-react-content'
import swal from "sweetalert2";
const Swal = withReactContent(swal)
 
function SimpleCondition(props){
    const {modelType , handleShow} = props
    const {taste , size} = useParams()
    const [loading, setLoading] = useState(false)
    const [productsRose , setProductsRose] = useState([])
    const [productsCarnation , setProductsCarnation] = useState([])
    const [productsGroups , setProductsGroups] = useState([])
    const [productsOthers , setProductsOthers] = useState([])
    const [productsDahlia , setProductsDahlia] = useState([])
    const [productsLily , setProductsLily] = useState([])
    const [productsLotus , setProductsLotus] = useState([])
    const [productsPeony , setProductsPeony] = useState([])


    const addCartSelect = (product) => {
        const dataObj = {token: localStorage.getItem('token')}
        Axios.post('https://berriesblossoms.com:3000/get/user' , dataObj).then(res => {
            var res = res.data
            if(res.status != 'success'){
                 Swal.fire({
                    title: <h4>กรุณาเข้าสู่ระบบก่อนสั่งซื้อ</h4>,
                    html: "",
                    icon: "error",
                    confirmButtonText: 'เข้าสู่ระบบ',
                }).then(() => {
                    window.location.href = '/login'
                })
                return false
            }
            const obj = {name:product.name , price:product.price , size:product.size, taste:product.taste ,  cover_image: product.cover_image , amount:1}
            let currentCart = localStorage.getItem("cart");
            currentCart = JSON.parse(currentCart)
            if(currentCart == null){
                currentCart = [obj]
            }else{
                currentCart.push(obj)
            }
            localStorage.setItem('cart' , JSON.stringify(currentCart))
            let newCart = localStorage.getItem("cart");
            props.setCart(JSON.parse(newCart).length)
            Swal.fire({
                title: <strong>เพิ่มลงตะกร้าแล้ว</strong>,
                html: "",
                icon: "success"
            })
        })
    }
    
    const productRoseElements = productsRose.map((product , index)  => {
        return <ProductCard key={index} handleShow={handleShow} addCartSelect={addCartSelect} product={product}/>
    })
    const productCarnationElements = productsCarnation.map((product , index)  => {
        return <ProductCard key={index} handleShow={handleShow} addCartSelect={addCartSelect} product={product}/>
    })
    const productGroupsElements = productsGroups.map((product , index)  => {
        return <ProductCard key={index} handleShow={handleShow} addCartSelect={addCartSelect} product={product}/>
    })
    const productOthersElements = productsOthers.map((product , index)  => {
        return <ProductCard key={index} handleShow={handleShow} addCartSelect={addCartSelect} product={product}/>
    })
    const productDahliaElements = productsDahlia.map((product , index)  => {
        return <ProductCard key={index} handleShow={handleShow} addCartSelect={addCartSelect} product={product}/>
    })
    const productLilyElements = productsLily.map((product , index)  => {
        return <ProductCard key={index} handleShow={handleShow} addCartSelect={addCartSelect} product={product}/>
    })
    const productLotusElements = productsLotus.map((product , index)  => {
        return <ProductCard key={index} handleShow={handleShow} addCartSelect={addCartSelect} product={product}/>
    })
    const productPeonyElements = productsPeony.map((product , index)  => {
        return <ProductCard key={index} handleShow={handleShow} addCartSelect={addCartSelect} product={product}/>
    })

    let [amount , setAmount] = useState(1)
    const addCart = (e) => {
        e.preventDefault()
        const dataObj = {token: localStorage.getItem('token')}
        Axios.post('https://berriesblossoms.com:3000/get/user' , dataObj).then(res => {
            var res = res.data
            if(res.status != 'success'){
                 Swal.fire({
                    title: <h4>กรุณาเข้าสู่ระบบก่อนสั่งซื้อ</h4>,
                    html: "",
                    icon: "error",
                    confirmButtonText: 'เข้าสู่ระบบ',
                }).then(() => {
                    window.location.href = '/login'
                })
                return false
            }
            let el = document.getElementById('amount')
            let color = document.getElementById('color')
            let carts = {amount: el.value , name:"ร้านค้าออกแบบให้" , price:100 , size:size , taste:taste, color:color.value , cart_type: 'custom'}
    
            let currentCart = localStorage.getItem("cart");
            currentCart = JSON.parse(currentCart)
            if(currentCart == null){
                currentCart = [carts]
            }else{
                currentCart.push(carts)
            }
            localStorage.setItem('cart' , JSON.stringify(currentCart))
            let newCart = localStorage.getItem("cart");
            props.setCart(JSON.parse(newCart).length)
    
            Swal.fire({
                title: <strong>เพิ่มลงตะกร้าแล้ว</strong>,
                html: "",
                icon: "success"
            })
        })
    }

    const changeAmount = (e) => {
        if(e == 'add'){
            setAmount(amount++)
        }else{
            setAmount(amount--)
        }
        let el = document.getElementById('amount')
        el.value = amount
    }

    useEffect(() => { // โหลดสินค้าครั้งแรก
        async function fetchData() {
            setLoading(true);
            const groupArr = ['rose','carnation','groups','others','dahlia','water_lily','lotus','peony']
            Axios.post('https://berriesblossoms.com:3000/get/product/simple', {taste , size , group:'rose'}).then(response => {
                const res = response.data
                setProductsRose(res.products)
            })
            Axios.post('https://berriesblossoms.com:3000/get/product/simple', {taste , size , group:'carnation'}).then(response => {
                const res = response.data
                setProductsCarnation(res.products)
            })
            Axios.post('https://berriesblossoms.com:3000/get/product/simple', {taste , size , group:'groups'}).then(response => {
                const res = response.data
                setProductsGroups(res.products)
            })
            Axios.post('https://berriesblossoms.com:3000/get/product/simple', {taste , size , group:'others'}).then(response => {
                const res = response.data
                setProductsOthers(res.products)
            })
            Axios.post('https://berriesblossoms.com:3000/get/product/simple', {taste , size , group:'dahlia'}).then(response => {
                const res = response.data
                setProductsDahlia(res.products)
            })
            Axios.post('https://berriesblossoms.com:3000/get/product/simple', {taste , size , group:'water_lily'}).then(response => {
                const res = response.data
                setProductsLily(res.products)
            })
            Axios.post('https://berriesblossoms.com:3000/get/product/simple', {taste , size , group:'lotus'}).then(response => {
                const res = response.data
                setProductsLotus(res.products)
            })
            Axios.post('https://berriesblossoms.com:3000/get/product/simple', {taste , size , group:'peony'}).then(response => {
                const res = response.data
                setProductsPeony(res.products)
            })
            setLoading(false);
        }
        fetchData()
    }, [modelType])


    const [banners , setBanners] = useState([])
    useEffect(() => {
        Axios.post('https://berriesblossoms.com:3000/get/banner' , {image_type:'store'}).then(imageRes => {
            setBanners(imageRes.data.banners)
        })
    }, [])

    let i = -1
    const bannersEl = banners.map((banner , index) => {
        i++
        const image = "https://berriesblossoms.com:3001/"+banner.cover_image
        return  <div className={`carousel-item ${i == 0 ? 'active' : ''}`} key={index}>
                    <img src={image} className="d-block w-100 rounded" alt="..."/>
                </div>
                
    })
    var ind = -1
    const bannersIndicatorsEl = banners.map((banner , index) => {
        ind++
        return  <button type="button" key={index} data-bs-target="#mainSlide" data-bs-slide-to={ind} className={ind == 0 ? 'active' : ''} aria-current="true" ></button>
    })

    let modelTypeElement
    if(modelType == 'store'){
        modelTypeElement = <div className='row justify-content-center'>
                                <form onSubmit={addCart} className='col-md-6'>
                                    <div className='row justify-content-center'>
                                        <div className='col-12 mb-4'></div>
                                        <div className='col-12 text-center'>
                                            <h6>ตัวอย่างการออกแบบจากร้านค้า</h6>
                                        </div>
                                        <div className='col-12'></div>
                                        <div className='col-12 mb-3'>
                                            <div id="mainSlide" className="carousel slide" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    {bannersIndicatorsEl}
                                                </div>
                                                <div className="carousel-inner">
                                                    {bannersEl}
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#mainSlide" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#mainSlide" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 mb-3'>
                                            <div className='amount-select'>
                                                <span className="me-2">จำนวน</span>
                                                <button type='button' className='minus' onClick={() => changeAmount('minus')}><i className="fal fa-minus"></i></button>
                                                <input type="number" value={amount} name="amount" id="amount" readOnly></input>
                                                <button type='button' className='plus' onClick={() => changeAmount('add')}><i className="fal fa-plus"></i></button>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <p className="text-danger">* กล่องมาตรฐานขนาดบรรจุ 4 ชิ้นและ 9 ชิ้น </p>
                                        </div>
                                        <div className='col-12'>
                                            <div className='form-group mb-3'>
                                                <label>โปรดระบุโทนสีที่ชอบ (กรณีที่ไม่ต้องการคละสี)</label>
                                                <input type="text" name="color" id="color" className='input-theme'></input>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <button type='submit' className='comfirm-model-btn w-100'>ยืนยัน <i className="fad fa-arrow-circle-right"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
    }else{ 
        modelTypeElement = <div>
                                <div className='product-slide-group mt-5'>
                                    <div className='d-flex mb-2'>
                                        <h5 className='mb-0'>ร้านค้าออกแบบให้ / Designer's Choice</h5>
                                    </div>
                                    {productsGroups.length >= 6  ? <ProductSlide products={productGroupsElements}/> : <div className="row">{productGroupsElements}</div> }
                                </div>
                                <div className='product-slide-group mt-5'>
                                    <div className='d-flex mb-2'>
                                        <h5 className='mb-0'>กุหลาบ / Rose</h5>
                                    </div>
                                    {productsRose.length >= 6  ? <ProductSlide products={productRoseElements}/> : <div className="row">{productRoseElements}</div> }
                                </div>
                                <div className='product-slide-group mt-5'>
                                    <div className='d-flex mb-2'>
                                        <h5 className='mb-0'>โบตั๋น / Peony</h5>
                                    </div>
                                    {productsPeony.length >= 6  ? <ProductSlide products={productPeonyElements}/> : <div className="row">{productPeonyElements}</div> }
                                </div>
                                <div className='product-slide-group mt-5'>
                                    <div className='d-flex mb-2'>
                                        <h5 className='mb-0'>บัวหลวง / Lotus</h5>
                                    </div>
                                    {productsLotus.length >= 6  ? <ProductSlide products={productLotusElements}/> : <div className="row">{productLotusElements}</div> }
                                </div>
                                <div className='product-slide-group mt-5'>
                                    <div className='d-flex mb-2'>
                                        <h5 className='mb-0'>บัวสาย / Water Lily</h5>
                                    </div>
                                    {productsLily.length >= 6  ? <ProductSlide products={productLilyElements}/> : <div className="row">{productLilyElements}</div> }
                                </div>
                                <div className='product-slide-group mt-5'>
                                    <div className='d-flex mb-2'>
                                        <h5 className='mb-0'>คาร์เนชั่น / Carnation</h5>
                                    </div>
                                    {productsCarnation.length >= 6  ? <ProductSlide products={productCarnationElements}/> : <div className="row">{productCarnationElements}</div> }
                                </div>
                                <div className='product-slide-group mt-5'>
                                    <div className='d-flex mb-2'>
                                        <h5 className='mb-0'>ดาห์เลีย / Dahlia</h5>
                                    </div>
                                    {productsDahlia.length >= 6  ? <ProductSlide products={productDahliaElements}/> : <div className="row">{productDahliaElements}</div> }
                                </div>
                                <div className='product-slide-group mt-5'>
                                    <div className='d-flex mb-2'>
                                        <h5 className='mb-0'>ดอกไม้อื่นๆ / Others</h5>
                                    </div>
                                    {productsOthers.length >= 6  ? <ProductSlide products={productOthersElements}/> : <div className="row">{productOthersElements}</div> }
                                </div>
                            </div>
    }

    return (<div>
                {modelTypeElement}
            </div>
    )
}

function SimplePage(props){
    const [modelType , setModelType] = useState('selection')
    const {taste , size} = useParams()
    const handleType = (e) => {
        const modelSelectBtn = document.getElementsByClassName('model-select-button')
        for (let i = 0; i < modelSelectBtn.length; i++) {
            const element = modelSelectBtn[i];
            element.classList.remove('active')
            if(element.dataset.modeltype == 'store'){
                element.innerHTML = "ร้านค้าออกแบบให้"
            }else{
                element.innerHTML = "ลูกค้าเลือกแบบเอง"
            }
        }
        e.target.classList.add('active')
        if(e.target.dataset.modeltype == 'store'){
            e.target.innerHTML = "<i class='fad fa-check-circle'></i> ร้านออกแบบให้"
        }else{
            e.target.innerHTML = "<i class='fad fa-check-circle'></i> ลูกค้าเลือกแบบเอง"
        }
        
        setModelType(e.target.dataset.modeltype)
    }

    return (
        <div>
            <div className='row justify-content-center d-none'>
                <div className='col-6 col-md-3'>
                    <div className='model-select-button active' data-modeltype="selection" onClick={handleType}>
                        <i className='fad fa-check-circle'></i> ลูกค้าเลือกแบบเอง
                    </div>
                </div>
                <div className='col-6 col-md-3'>
                    <div className='model-select-button ' data-modeltype="store" onClick={handleType}>
                         ร้านออกแบบให้
                    </div>
                </div>
            </div>
            <SimpleCondition handleShow={props.handleShow} modelType={modelType} setCart={props.setCart}/>
        </div>
    )
}

export default SimplePage