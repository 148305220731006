import React from "react";
import Slider from "react-slick";
import ProductCard from "../components/ProductCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
      <a href="#" onClick={onClick} className="arrows-slide left"><i className="fas fa-arrow-alt-circle-left"></i></a>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
      <a href="#" onClick={onClick} className="arrows-slide right"><i className="fas fa-arrow-alt-circle-right"></i></a>
  );
}
  

function ProductSlide(props){
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
    };
    return (
        <div className="position-relative mx--15">
            <Slider {...settings}>
              {props.products}
            </Slider>
        </div>
    )
}

export default ProductSlide;