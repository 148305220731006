import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import Header from '../components/Header';
import Banner from '../components/Banner';
import ProductCard from '../components/ProductCard';
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';
import SelectTaste from '../components/SelectTaste';
import ProductSlide from '../components/ProductSlide';
import { useParams , Link , useNavigate, withRouter } from 'react-router-dom';
import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios'
process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

function HomePage(){

    const iframeRef = React.createRef()
    

    useEffect(() => {
        Axios.post('https://berriesblossoms.com:3000/get/video').then(response => {
            const res = response.data
            var regExp =    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;  
            var match = res.video.match(regExp);  
            if (match && match[7].length == 11) {    
                iframeRef.current.src = "https://www.youtube.com/embed/"+match[7]
            }
        })  
         
    })

    return (
    <div className='HomePage'>
        <Header/>
        <Banner/>
        <AboutUs/>
        <div className='bg-lightgray mb-5 mt-3'>
            <SelectTaste/>
            <div className='container'>
                <h4 className='mt-5 mb-4'>วีดีโอแนะนำ</h4>
                <iframe  width="100%" height="600" src="" ref={iframeRef} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
      <Footer/>
    </div>
    )
}

export default HomePage