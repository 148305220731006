import Header from "../components/Header"
import ProductForm from "../components/ProductForm";
import Axios from 'axios'
import { useParams, Link, useNavigate, withRouter } from 'react-router-dom';
import React, { useState, useContext, useEffect } from 'react';
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProductAdminCard from "../components/ProductAdminCard";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const Swal = withReactContent(swal)



function AdminProduct() {

    const navigate = useNavigate()
    const [selectionTaste , setSelectionTaste] = useState([])
    const [selectionNormalSize , setSelectionNormalSize] = useState([])
    const [selectionSpecialSize , setSelectionSpecialSize] = useState([])
    const [selectionGroup , setSelectionGroup] = useState([])
    const [simpleGroupEl , setSimpleGroupEl] = useState()
    const handleCheckTaste = (e) => {
        const val = e.target.value
        const taste = selectionTaste
        if(!taste.includes(val)){
            taste.push(val)
           
        }else{
            let index = taste.indexOf(val);
            taste.splice(index , 1)
            
        }
        setSelectionTaste(taste)
    }

    const handleCheckGroup = (e) => {
        const val = e.target.value
        const group = selectionGroup
        if(!group.includes(val)){
            group.push(val)
           
        }else{
            let index = group.indexOf(val);
            group.splice(index , 1)
            
        }
        setSelectionGroup(group)
    }

    const handleCheckNormalSize = (e) => {
        const val = e.target.value
        const size = selectionNormalSize
        if(!size.includes(val)){
            size.push(val)
        }else{
            let index = size.indexOf(val);
            size.splice(index , 1)
            setSimpleGroupEl("")
        }
        if(selectionTaste.includes('original', 'choco_berries' , 'yusu_blossoms')){
            if(size.includes('simple')){
                setSimpleGroupEl(<div className="row mt-3">
                                    <div className="col-12 d-flex">
                                        <h6>หมวดหมู่สินค้า <span className="text-danger">**กรุณาเลือกหมวดหมู่สินค้า</span></h6>
                                    </div>
                                    <div className="col-6 col-md-4 text-left">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={handleCheckGroup} value="rose" id="roseCheck" />
                                            <label className="form-check-label" htmlFor="roseCheck">
                                                กุหลาบ / Rose
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 text-left">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={handleCheckGroup} value="peony" id="peonyCheck" />
                                            <label className="form-check-label" htmlFor="peonyCheck">
                                                โบตั๋น / Peony
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 text-left">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={handleCheckGroup} value="lotus" id="lotusCheck" />
                                            <label className="form-check-label" htmlFor="lotusCheck">
                                                บัวหลวง / Lotus
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 text-left">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={handleCheckGroup} value="water_lily" id="water_lilyCheck" />
                                            <label className="form-check-label" htmlFor="water_lilyCheck">
                                                บัวสาย / Water Lily
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 text-left">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={handleCheckGroup} value="carnation" id="carnationCheck" />
                                            <label className="form-check-label" htmlFor="carnation">
                                                คาร์เนชั่น / Carnation
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 text-left">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={handleCheckGroup} value="dahlia" id="dahliaCheck" />
                                            <label className="form-check-label" htmlFor="dahliaCheck">
                                                ดาห์เลีย / Dahlia
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 text-left">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={handleCheckGroup} value="groups" id="groupsCheck" />
                                            <label className="form-check-label" htmlFor="groupsCheck">
                                            ร้านค้าออกแบบให้ / Designer's Choice
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 text-left">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={handleCheckGroup} value="others" id="othersCheck" />
                                            <label className="form-check-label" htmlFor="othersCheck">
                                                ดอกไม้อื่นๆ / Others
                                            </label>
                                        </div>
                                    </div>
                                    
                                </div>)
        }
        }

        setSelectionNormalSize(size)
    }

    const handleCheckSpecialSize = (e) => {
        const val = e.target.value
        const size = selectionSpecialSize
        if(!size.includes(val)){
            size.push(val)
        }else{
            let index = size.indexOf(val);
            size.splice(index , 1)
        }
        setSelectionSpecialSize(size)
    }

    function addProductSubmit(e) {
        e.preventDefault()
        const formData = new FormData(e.target)
        console.log('taste' , selectionTaste)
        console.log('normal-size' , selectionNormalSize)
        console.log('special-size' , selectionSpecialSize)
        console.log('groups' , selectionGroup)
        formData.append('taste' , selectionTaste)
        formData.append('normal_size' , selectionNormalSize)
        formData.append('special_size' , selectionSpecialSize)
        formData.append('groups' , selectionGroup)
        if(selectionTaste.length == 0){
            Swal.fire({
                title: <strong>ระบบแจ้งเตือน !!</strong>,
                html: <small>กรุณาเลือกรสชาด</small>,
                icon: 'warning'
            })
            return false
        }
        if(selectionNormalSize.length == 0 && selectionSpecialSize.length == 0){
            Swal.fire({
                title: <strong>ระบบแจ้งเตือน !!</strong>,
                html: <small>กรุณาเลือกขนาด</small>,
                icon: 'warning'
            })
            return false
        }
        if(selectionTaste.includes('original', 'choco_berries' , 'yusu_blossoms')){
            if(selectionNormalSize.includes('simple')){
                if(selectionGroup.length == 0){
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน !!</strong>,
                        html: <small>กรุณาเลือกหมวดหมู่สินค้า</small>,
                        icon: 'warning'
                    })
                    return false
                }
            }
        }
   
        Axios.post('https://berriesblossoms.com:3001/uploads.php?type=multiple' , formData).then(imageRes => {
            if(imageRes.data.success){
                formData.append('image_name' , imageRes.data.cover_image)
                    Axios.post('https://berriesblossoms.com:3000/add/multi/product' , formData).then(res => {
                        console.log(res)
                        if(res.data.success){
                            Swal.fire({
                                title: <strong>ระบบแจ้งเตือน</strong>,
                                html: <small>เพิ่มสินค้าสำเร็จ</small>,
                                icon: 'success'
                            }).then(() => {
                                window.location.reload(false);
                            })
                        }else{
                            Swal.fire({
                                title: <strong>ระบบแจ้งเตือน</strong>,
                                html: <small>เพิ่มสินค้าไม่สำเร็จโปรดลองใหม่อีกครั้ง</small>,
                                icon: 'error'
                            })
                        }
                    })
            }else{
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>อัพโหลดรูปภาพไม่สำเร็จ</small>,
                    icon: 'error'
                })
            }
        })
    }

    return (
        <div>
            <Header page={'products'} />
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={addProductSubmit}>
                                    <div className="form-group-file mb-3">
                                        <label>รูปสินค้า <span className="text-danger">(ขนาด 800x800 , อัพโหลดครั้งละไม่เกิน 20 รูป)</span></label>
                                        <br></br>
                                        <input type="file" name="cover_image[]" accept="image/png, image/jpeg" multiple className="form-control-file mt-2" required />
                                    </div>
                                    <div className="form-group">
                                        <label>ราคาสินค้า</label>
                                        <input type="number" name="price" className="form-control" required />
                                    </div>
                                    <div className='row'>
                                        <div className="col-12 d-flex">
                                            <h6><span className="badge rounded-pill bg-warning text-dark">1.</span> เลือกรสชาติ</h6>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckTaste} value="original" id="originalCheck" />
                                                <label className="form-check-label" htmlFor="originalCheck">
                                                    Original
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckTaste} value="choco_berries" id="choco_berriesCheck" />
                                                <label className="form-check-label" htmlFor="choco_berriesCheck">
                                                    Choco Berries
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckTaste} value="yusu_blossoms" id="yusu_blossomsCheck" />
                                                <label className="form-check-label" htmlFor="yusu_blossomsCheck">
                                                    Yusu Blossoms
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckTaste} value="yusu_premium" id="yusu_premiumCheck" />
                                                <label className="form-check-label" htmlFor="yusu_premiumCheck">
                                                    Yusu Premium
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckTaste} value="fruity_berries" id="fruity_berriesCheck" />
                                                <label className="form-check-label" htmlFor="fruity_berriesCheck">
                                                    Fruity Berries
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckTaste} value="special_event" id="special_eventCheck" />
                                                <label className="form-check-label" htmlFor="special_eventCheck">
                                                    Special Event
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 d-flex">
                                            <h6><span className="badge rounded-pill bg-warning text-dark">2.</span> เลือกขนาด</h6>
                                        </div>
                                        <div className="w-100 text-muted"><span>ทั่วไป</span></div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckNormalSize} value="simple" id="simpleCheck" />
                                                <label className="form-check-label" htmlFor="simpleCheck">
                                                    Simple
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckNormalSize} value="merry" id="merryCheck" />
                                                <label className="form-check-label" htmlFor="merry">
                                                    Merry
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckNormalSize} value="love" id="loveCheck" />
                                                <label className="form-check-label" htmlFor="loveCheck">
                                                    Love
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckNormalSize} value="extralove" id="extraloveCheck" />
                                                <label className="form-check-label" htmlFor="extraloveCheck">
                                                    Extra Love
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckNormalSize} value="forever" id="foreverCheck" />
                                                <label className="form-check-label" htmlFor="foreverCheck">
                                                    Forever
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-100"><hr/></div>
                                        <div className="w-100 text-muted"><span>Special</span></div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckSpecialSize} value="moon_festival" id="moon_festivalCheck" />
                                                <label className="form-check-label" htmlFor="moon_festivalCheck">
                                                   Moon Festival
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckSpecialSize} value="vegetarien" id="vegetarienCheck" />
                                                <label className="form-check-label" htmlFor="vegetarien">
                                                    Vegetarien Festival
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckSpecialSize} value="mother_day" id="mother_dayCheck" />
                                                <label className="form-check-label" htmlFor="mother_dayCheck">
                                                    Mother's Day
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckSpecialSize} value="special_size" id="special_sizeCheck" />
                                                <label className="form-check-label" htmlFor="special_sizeCheck">
                                                    Special Size
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckSpecialSize} value="happy_berries" id="happy_berriesCheck" />
                                                <label className="form-check-label" htmlFor="happy_berriesCheck">
                                                    Happy Berries
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleCheckSpecialSize} value="chinese_new_year" id="chinese_new_yearCheck" />
                                                <label className="form-check-label" htmlFor="chinese_new_yearCheck">
                                                    Chinese New Year
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {simpleGroupEl}
                                    <div className="text-right">
                                        <button type="submit" className="btn btn-primary">
                                            เพิ่มสินค้า
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminProduct