import Header from "../components/Header"
import React , { useState , useEffect } from "react";
import { Link , useNavigate , useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import Axios from 'axios'

function MyOrders(){
    const { id } = useParams();
    const [order , setOrder] = useState([])
    const [orderDetail , setOrderDetail] = useState([])
    useEffect(() => {
        async function loadOrder(){
            Axios.post('https://berriesblossoms.com:3000/order/' , { id:id }).then(response => {
                const res = response.data
                setOrder(res.order)
                setOrderDetail(res.order.details)
            })
        }
        loadOrder()
    } , [])

    let total = 0
    const cartElements = orderDetail.map((product , index) => {
        total += product.price * product.amount
        let image = product.cover_image;
        let theme_color
        if(product.cart_type != undefined){
            image = 'store_custom.jpg'
            theme_color = <span>โทนสี : {product.color}</span>
        }
        var divStyle = {
            backgroundImage: 'url(http://45.91.134.18/uploads/'+ image+')'
        };
        let typeNameArr = {original:"Original" , choco_berries:'Choco Berries' , yusu_blossoms: 'Yusu Blossoms' , yusu_premium:'Yusu Premium', fruity_berries:'Fruity Berries' , special_event:'Special Event'}
        let sizeNameArr = {
            simple: 'Simple',
            merry: 'Merry',
            love: 'Love',
            extralove: 'Extra Love',
            forever: 'Forever',
            moon_festival: 'Moon Festival',
            vegetarien: 'Vegetarien Festival',
            mother_day: "Mother's Day",
            special_size: 'Roseberries',
            happy_berries: 'Happy Berries',
            chinese_new_year: 'Chinese New Year',
        }
        return <tr key={index} data-cartkey={index}>
                    <td><div style={divStyle} className="cart-image" /></td>
                    <td>
                        รสชาด : {typeNameArr[product.taste]}<br/>
                        ขนาด : {sizeNameArr[product.size]}<br/>
                        {theme_color}
                    </td>
                    <td>{product.price} บาท</td>
                    <td>x{product.amount}</td>
                </tr>
    })
 
    return(
        <div className="myOrdersPage">
            <Header/>
            <div className="container mt-5" >
                <div className="text-right">
                    <Link to="/myorders" className="btn btn-sm btn-danger"><i className="fas fa-chevron-double-left"></i> กลับ</Link>
                </div>
                <div className="row">
                    <div className="col-md-8 mb-3">
                        <h4>รายละเอียดคำสั่งซื้อ</h4>
                        <div className="cart-card">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>รูปสินค้า</th>
                                        <th>รายละเอียดสินค้า</th>
                                        <th>ราคา/ชิ้น</th>
                                        <th>จำนวน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartElements}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>รวม : {total} บาท</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h4>ข้อมูลการจัดส่ง</h4>
                        <div  className="cart-card">
                            <div className="cart-form-group">
                                <label><i className="fas fa-user"></i> ชื่อผู้ติดต่อ <span className="text-danger">*</span></label>
                                <input type="text" value={order.fullname} className="cart-form-control" readOnly/>
                            </div>
                            <div className="cart-form-group">
                                <label><i className="fas fa-map-marker-alt"></i> ที่อยู่ <span className="text-danger">*</span></label>
                                <textarea className="cart-form-control" id="address" rows="2" value={order.address}  readOnly></textarea>
                            </div>
                            <div className="cart-form-group">
                                <label><i className="fas fa-phone"></i> เบอร์โทรศัพท์ <span className="text-danger">*</span></label>
                                <input type="text" id="phone" value={order.phone} className="cart-form-control" readOnly/>
                            </div>
                            <div className="cart-form-group">
                                <label><i className="fas fa-map-marker-alt"></i> โซนที่อยู่ <span className="text-danger">*</span></label>
                                <input type="text" value={order.zone} className="cart-form-control" readOnly/>
                            </div>
                            <div className="row">
                                <div className="cart-form-group col-12">
                                    <label><i className="fas fa-calendar-week"></i> วันที่ต้องการรับ <span className="text-danger">*</span></label>
                                    <input type="text" value={order.date} className="cart-form-control" readOnly/>
                                </div>
                                <div className="cart-form-group col-12">
                                    <label><i className="fas fa-clock"></i> เวลา<span className="text-danger">*</span></label>
                                    <input type="text" value={order.time} className="cart-form-control" readOnly/>
                                </div>
                            </div>
                            <div className="cart-form-group">
                                <label><i className="fas fa-birthday-cake"></i> คำอวยพร (เขียนลงบนการ์ด)</label>
                                <textarea className="cart-form-control" id="wish" value={order.wish} rows="2" readOnly></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyOrders