
function ProductCard(props){

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const { product }  = props
    const image = 'https://berriesblossoms.com:3001/'+product.cover_image
    return (
        <div className="col-check col-6 mb-3 col-md-2">
            <div className="product-card" key={product._id}>
                <img src={image} onClick={() => props.handleShow(image)} className="w-100 hover mb-3"/>
                <div className='d-flex'>
                    <h6 className='product-name mb-0'>{product.name}</h6>
                    <h6 className='product-price ms-auto  mb-0'>{numberWithCommas(product.price)} บาท</h6>
                </div>
                <div className="product-card-action">
                    <div className="amount-action">
                        <button className="addcart-btn" onClick={() => props.addCartSelect(product)}><i className="fas fa-cart-arrow-down"></i> เพิ่มลงตะกร้า</button>
                    </div>
                </div>
            </div> 
        </div>
       
    )
}

export default ProductCard