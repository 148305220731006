import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'font-awesome/css/font-awesome.min.css';
import '../assets/css/main.css'
import { useEffect } from 'react'
import logo from '../../assets/imgs/highres_logo.png';
import { Link , useNavigate , useLocation  } from 'react-router-dom';
import Axios from 'axios'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const Swal = withReactContent(swal)


function Header(props){

    const {page} = props
    const location = useLocation();
    const myRoute = location.pathname == '/admin' ? 'hi' : ''
    const navigate = useNavigate()
    const dataObj = {token: localStorage.getItem('admin_token')}
    useEffect(() => {
        Axios.post('https://berriesblossoms.com:3000/get/admin' , dataObj).then(res => {
            var res = res.data
            if(res.status != 'success'){
                navigate('/admin/login')
            }
        })
    })
    
    function locationTO(e){
        // navigate(e.target.dataset.location)
        window.location.href = e.target.dataset.location
    }


    return (
        <header className='admin-header'>
            <div className='container'>
                <ul className='header-list'>
                    <li className='header-item'>
                        <Link to="/" className='logo-admin'><img src={logo} className="header-logo"/></Link>
                    </li>
                    <li className={`header-item first-menu ${page == 'orders' ? 'active' : ''}`}>
                        <Link className={`header-link`} to="/admin"><i className="fas fa-bags-shopping"></i> คำสั่งซื้อ</Link>
                    </li>
                    <li className={`dropdown header-item ${page == 'products' ? 'active' : ''}`}>
                        <a className="dropdown-toggle header-link" type="button" id="dropdownMenuButtonProduct" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fas fa-pie"></i>  จัดการสินค้า
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonProduct">
                            <li><a className="dropdown-item" onClick={locationTO} href="#" data-location="/admin/addmulti/product">เพิ่มสินค้าหลายรายการ</a></li>
                            <li><a className="dropdown-item" onClick={locationTO} href="#" data-location="/admin/banners?type=store">ร้านออกแบบให้</a></li>
                            <li><hr class="dropdown-divider"/></li>
                            <li><a className="dropdown-item" onClick={locationTO} href="#" data-location="/products?taste=original&size=simple&group=rose">Original</a></li>
                            <li><a className="dropdown-item" onClick={locationTO} href="#" data-location="/products?taste=choco_berries&size=simple&group=rose">Chocco berries</a></li>
                            <li><a className="dropdown-item" onClick={locationTO} href="#" data-location="/products?taste=yusu_blossoms&size=simple&group=rose">Yusu blossoms</a></li>
                            <li><a className="dropdown-item" onClick={locationTO} href="#" data-location="/products?taste=yusu_premium&size=simple">Yusu premium</a></li>
                            <li><a className="dropdown-item" onClick={locationTO} href="#" data-location="/products?taste=fruity_berries&size=simple">Fruity Berries</a></li>
                            <li><a className="dropdown-item" onClick={locationTO} href="#" data-location="/products?taste=special_event&size=moon_festival">Special Event</a></li>
                        </ul>
                    </li>
                    <li className={`header-item ${page == 'banners' ? 'active' : ''}`}>
                        <a className="header-link" onClick={locationTO} href="#"   data-location="/admin/banners?type=banner"><i className="fas fa-image"></i> แบนเนอร์</a>
                    </li>
                    <li className={`header-item ${page == 'video' ? 'active' : ''}`}>
                        <Link className="header-link" to="/admin/video"><i className="fab fa-youtube"></i> วีดีโอ</Link>
                    </li>
                    <li className={`header-item ${page == 'landing' ? 'active' : ''}`}>
                        <Link className="header-link" to="/admin/landing"><i className="fas fa-home"></i> หน้าแลนดิ่ง</Link>
                    </li>
                    <li className='header-item'>
                        <Link className='header-link'><i className="fas fa-door-open"></i> ออกจากระบบ</Link>
                    </li>
                </ul>
            </div>
        </header>
    )
}

export default Header