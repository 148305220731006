import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React , {useState , useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { useParams , useNavigate} from 'react-router-dom';

  
function SelectSizePage(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { taste } = useParams();
    const [sizeImage , setSizeImage] = useState(taste != 'special_event' ? '../../images/'+taste+'/simple.jpg' : '../../images/'+taste+'/moon_festival.jpg')
    const navigate = useNavigate()
    const currentSize = taste != 'special_event' ? 'simple' : 'moon_festival'
    const [Loading , setLoading] = useState(false)
    const [sizeSelect , setSizeSelect] = useState(taste != 'special_event' ? 'Simple' : 'Moon Festival')
    const [sizeReal , setSizeReal] = useState(currentSize)
    const [sizeDescription , setSizeDescription] = useState(<div className='sizeDes'>
                                                                <p><span>1</span>ขนาดเล็ก สำหรับทานคนเดียว</p>                
                                                                <p><span>2</span>เส้นผ่าศูนย์กลาง 7 cm สูง 3.5 cm</p>                
                                                                <p><span>3</span>กล่องเล็ก 4 ชิ้น กล่องใหญ่ 9 ชิ้น</p>                
                                                            </div>)
    useEffect(() => {
        if(sizeSelect == 'Simple'){
            setSizeDescription(<div className='sizeDes'>
                                <p><span>1</span>ขนาดเล็ก สำหรับทานคนเดียว</p>                
                                <p><span>2</span>เส้นผ่าศูนย์กลาง 7 cm สูง 3.5 cm</p>                
                                <p><span>3</span>กล่องเล็ก 4 ชิ้น กล่องใหญ่ 9 ชิ้น</p>                
                            </div>)
        }else if(sizeSelect == 'Merry'){
            setSizeDescription(<div className='sizeDes'>
                                <p><span>1</span>ขนาด 1/2 ปอนด์</p>                
                                <p><span>2</span>สำหรับ 3-4 ท่าน</p>                
                                <p><span>3</span>เส้นผ่าศูนย์กลาง 12 cm สูง 6 cm</p>                
                                <p><span className="free">4</span>ฟรี...ป้าย HBD/เทียน/การ์ดอวยพร</p>                
                            </div>)
        }else if(sizeSelect == 'Love'){
            setSizeDescription(<div className='sizeDes'>
                                    <p><span>1</span>ขนาด 1 ปอนด์</p>                
                                    <p><span>2</span>สำหรับ 6-8 ท่าน</p>                
                                    <p><span>3</span>เส้นผ่าศูนย์กลาง 15 cm สูง 6 cm</p>                
                                    <p><span class="free">4</span>ฟรี... กล่อง Premuim /ป้าย HBD/เทียน/การ์ดอวยพร</p>                
                                </div>)
        }else if(sizeSelect == 'Extra Love'){
            setSizeDescription(<div className='sizeDes'>
                                    <p><span>1</span>ขนาด 2 ปอนด์</p>   
                                    <p><span>2</span>เส้นผ่าศูนย์กลาง 19 cm สูง 6.5 cm</p>               
                                    <p><span>3</span>สำหรับ 10-12 ท่าน</p>                
                                    <p><span className="free">4</span>ฟรี... กล่อง Premuim /ป้าย HBD/เทียน/การ์ดอวยพร</p>                
                                </div>)
        }else if(sizeSelect == 'Forever'){
            setSizeDescription(<div className='sizeDes'>
                                    <p><span>1</span>ขนาด 3 ปอนด์</p>   
                                    <p><span>2</span>เส้นผ่าศูนย์กลาง 23 cm สูง 6.5 cm</p>               
                                    <p><span>3</span>สำหรับ 14-16 ท่าน</p>                
                                    <p><span className="free">4</span>ฟรี... กล่อง Premuim /ป้าย HBD/เทียน/การ์ดอวยพร</p>                
                                </div>)
        }else if(sizeSelect == 'Moon Festival'){
            setSizeDescription(<div className='sizeDes'>
                                    <p>มีให้เลือก​ 2​ รสชาติ​ คือ​ ทุเรียนหมอนทอง​ และไข่แดง​ อัดแน่นอยู่ในดวงจันทร์​กลมโต พร้อมแทรกเนื้อพุทราจีน​ เมล็ดบัว​ และเกาลัดให้เคี้ยวหนุบหนับไว้ในฐานเบอรี่​ซิกเนเจอร์​ผสมน้ำทับทิม​</p>                
                                </div>)
        }else if(sizeSelect == 'Vegetarian Festival'){
            setSizeDescription(<div className='sizeDes'>
                                    <p>หวานกลม​กล่อ​มด้วยน้ำตาลจากดอก​มะพร้าว​ และ​เมเปิ้ล​ไซรัป​ 
                                    พร้อมแทรกเมล็ดบัว​ และลูกเกดสีทองไว้ที่ฐาน​ ให้เคี้ยว​กรุบๆ​ หนุบๆ​ หนับๆ ชื่นใจสุดๆ</p>                
                                </div>)
        }else if(sizeSelect == "Mother's Day"){
            setSizeDescription(<div className='sizeDes'>
                                    <p>ลายดอกมะลิ​ลอยบนพื้นน้ำ​สีฟ้า มาพร้อมฐานยูสุเบอร์รี่​หอมชื่นใจ</p>                
                                </div>)
        }else if(sizeSelect == 'Roseberries'){
            setSizeDescription(<div className='sizeDes'>
                                    <p>ลายกุหลาบ บนพื้นสีชมพูที่อบอวลด้วยกลิ่นกุหลาบ<br/>เพิ่มความฟินด้วยชิ้นวุ้นกุหลาบแทรกในฐาน เคี้ยวปุ๊ปฟินปั๊บ หนุบหนับๆ</p>                
                                </div>)
        }else if(sizeSelect == 'Happy Berries'){
            setSizeDescription(<div className='sizeDes'>
                                    <p>ตกแต่ง​ดอกไม้พร้อมผลสตรอว์​เบ​อร์รี่​สดๆในเนื้อวุ้นใส​ และเติมความฟินด้วยก้อน​ Valrhona Chocolate​ เกรด​พรีเมียม​ (CARAIBE 66%) แทรกไว้ที่ฐานยู​สุ​เบอร์รี่​ ให้รสชาติ​กลมกล่อม​พิเศษ​สุดๆ</p>                
                                </div>)
        }else if(sizeSelect == 'Chinese New Year'){
            setSizeDescription(<div className='sizeDes'>
                                    <p>ตรุษจีน​ปีนี้​ มี​ 2​ สูตรให้เลือกนะคะ​ ​สูตร​ 1 Precious  Blossoms ลายโบตั๋นสีแดง กับเหมยฮัวสีชมพู​ และสูตร​ 2 Yuzu​ Premium​ ลายน้องส้มกลมกิ๊ก

                                    ทั้ง​ 2​ สูตร​ พื้นเป็นสีทองมงคล​ทำจากน้ำส้มยูสุผสานกับ​ Edible Food​ Grade​ Gold​ powder​ และฐานเป็น​ยู​สุ​เบอร์รี่​</p>                
                                </div>)
        }


        
    }, [sizeSelect])
   

    let sizeEl
    if(taste == 'original'){
        sizeEl = <div className=' taste-item-header original'>
                    <h1>ORIGINAL</h1>
                    <p>ฐานน้ำเบอรี่รวมหลายชนิด หวานกลมกล่อมด้วยน้ำผึ้งและน้ำหวานจากดอกมะพร้าว เป็น Signature ของ Berries n' Blossoms</p>
                </div>
    }else if(taste == 'choco_berries'){
        sizeEl = <div className=' taste-item-header choco_berries'>
                    <h1>CHOCO BERRIES</h1>
                    <p>แทรกชิ้นช็อกโกแลตเกรดพรีเมียมไว้ในพื้นฐาน เพื่อให้รสชาติเข้มๆของช็อกโกแลตตัดกับความเปรี้ยวหวานของฐานยูสุเบอรี่ เปรี้ยวจิ๊ดหวานหน่อย อร่อยครบรส</p>
                </div>
    }else if(taste == 'yuzu_blossoms'){
        sizeEl = <div className=' taste-item-header yuzo_berries'>
                    <h1>Yuzu Blossoms</h1>
                    <p>แทรกเนื้อวุ้นรสส้มยูสุไว้ในฐาน ให้เคี้ยวกรุบๆ เปรี้ยวๆหวานๆคะเคล้ากับฐานรสยูสุที่หอมสดชื่น</p>
                </div>
    }else if(taste == 'yuzu_premium'){
        sizeEl = <div className=' taste-item-header yuzo_premium'>
                    <h1>Yuzu Premium</h1>
                    <p>ลายน้องส้มกลมกิ๊ก พื้นเป็นสีทองจาก Edible Food Grade Gold powder พร้อมเนื้อส้มสดๆไว้ในฐานยูสุเบอร์รี่ เมื่อเคี้ยวโดนเกล็ดส้มจะแตกเป๊ะป๊ะในปาก ทั้งฟินทั้งอร่อย</p>
                </div>
    }else if(taste == 'fruity_berries'){
        sizeEl = <div className=' taste-item-header fruity_berries'>
                    <h1>Fruity Berries</h1>
                    <p>แทรกผลไม้สดในเนื้อวุ้นใสและในฐานเบอรี่ เพื่อให้สัมผัสกับความสดชื่นของผลไม้สดพร้อมไปกับรสชาติของเบอร์รี่ซิกเนเจอร์</p>
                </div>
    }else if(taste == 'special_event'){
        sizeEl = <div className=' taste-item-header special_event'>
                    <h1>SPECIAL EVENT</h1>
                    <p>น้องวุ้นรุ่น Limited Edition สร้างสรรค์ตามเทศกาลสำคัญ หรือโอกาสสุดพิเศษ</p>
                </div>
    }

    const selectSize = (e) => {
        const sizes = document.getElementsByClassName('select-size')
        for (let i = 0; i < sizes.length; i++) {
            sizes[i].classList.remove("active");
            sizes[i].innerHTML = sizes[i].dataset.size
        }
        e.target.classList.add('active')
        e.target.innerHTML = "<span><i class='fad fa-check-circle me-2'></i>"+e.target.dataset.size+"</span>"
        setSizeSelect(e.target.dataset.size)
        setSizeReal(e.target.dataset.real)
        console.log(e.target.dataset.real)
        setSizeImage('../../images/'+taste+'/'+e.target.dataset.real+'.jpg')
    }

    const confirmSize = (e) => {
        const base_url = window.location.origin.toString()
        window.location = base_url+'/select_model/'+taste+'/'+sizeReal
    }

    let selectSizeGroup
    if(taste != "special_event"){
        selectSizeGroup =  <div className='select-size-group'>
                                <div className='select-size-item' >
                                    <div className='select-size active' data-real="simple" data-size="Simple" onClick={selectSize}>
                                       <span><i className='fad fa-check-circle me-2'></i>Simple</span> 
                                    </div>
                                </div>
                                <div className='select-size-item' >
                                    <div className='select-size' data-real="merry" data-size="Merry" onClick={selectSize}>
                                        Merry
                                    </div>
                                </div>
                                <div className='select-size-item'>
                                    <div className='select-size ' data-real="love" data-size="Love" onClick={selectSize}>
                                        Love
                                    </div>
                                </div>
                                <div className='select-size-item' >
                                    <div className='select-size ' data-real="extralove" data-size="Extra Love" onClick={selectSize}>
                                        Extra Love
                                    </div>
                                </div>
                                <div className='select-size-item' >
                                    <div className='select-size ' data-real="forever" data-size="Forever" onClick={selectSize}>
                                        Forever
                                    </div>
                                </div>
                            </div>
    }else{
        selectSizeGroup =  <div className='select-size-group'>
                                <div className='select-size-item' >
                                    <div className='select-size active' data-real="moon_festival" data-size="Moon festival" onClick={selectSize}>
                                        <span><i className='fad fa-check-circle me-2'></i>Moon festival</span>
                                    </div>
                                </div>
                                <div className='select-size-item' >
                                    <div className='select-size' data-real="vegetarien" data-size="Vegetarian Festival" onClick={selectSize}>
                                        Vegetarian Festival
                                    </div>
                                </div>
                                <div className='select-size-item'>
                                    <div className='select-size ' data-real="mother_day" data-size="Mother's Day" onClick={selectSize}>
                                        Mother's Day
                                    </div>
                                </div>
                                <div className='select-size-item' >
                                    <div className='select-size ' data-real="special_size" data-size="Roseberries" onClick={selectSize}>
                                        Roseberries
                                    </div>
                                </div>
                                <div className='select-size-item' >
                                    <div className='select-size ' data-real="happy_berries" data-size="Happy Berries" onClick={selectSize}>
                                        Happy Berries
                                    </div>
                                </div>
                                <div className='select-size-item' >
                                    <div className='select-size ' data-real="chinese_new_year" data-size="Chinese New Year" onClick={selectSize}>
                                        Chinese New Year
                                    </div>
                                </div>
                            </div>
    }


    return (
    <div className='SelectSizePage'>
        <Header/>
        <div className='content-page my-5'>
            <div className='select-size-container container'>
                <div className='select-size-card'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 className='text-center  mb-3'>กรุณาเลือก{taste != 'special_event' ? 'ขนาด' : null}</h3>
                            <div className='selectedTaste'>
                                {sizeEl}
                            </div>
                        </div>
                        <div className='col-md-12 mb-5'>
                           {selectSizeGroup}
                        </div>
                        <div className='col-12 rounded'>
                            <div className='bg-lightgray'>
                                <div className='row position-relative'>
                                    <div className='col-md-4'>
                                        <img  src={sizeImage} className='w-100 rounded' />
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='position-relative sm-mb-53px'>
                                            <div className='d-flex'>
                                                <h4 className='size-title-selected'>{sizeSelect}</h4>
                                            </div>
                                            {sizeDescription}
                                        </div>
                                    </div>
                                    <button type='button' onClick={confirmSize} className='choose-btn'>เลือก <i className="fad fa-arrow-circle-right"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='margin-footer'></div>
      <Footer/>
    </div>
    )
}

export default SelectSizePage