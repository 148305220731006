import Axios from 'axios'
import swal from 'sweetalert2'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import { useState , useContext , useEffect } from 'react';
const Swal = withReactContent(swal)

function Home() {

    const [inputs, setInputs] = useState({});
    const navigate = useNavigate()
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const dataObj = {username:inputs.username , password:inputs.password}
        Axios.post('https://berriesblossoms.com:3000/admin/login' , dataObj).then(res => {
            var res = res.data
            if(res.loggedIn){
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>เข้าสู่ระบบสำเร็จ</small>,
                    icon: 'success'
                }).then(() => {
                    localStorage.setItem('admin_token' , res.token)
                    navigate('/admin')
                })
            }else{
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>{res.msg}</small>,
                    icon: 'error'
                })
            }
        })
    }

    return (
        <div className="container mt-5 pt-5">
            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="text-center mb-0">เข้าสู่ระบบจัดการหลังบ้าน</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label  className="form-label">Username</label>
                                    <input type="text" name='username' value={inputs.username || ""} onChange={handleChange} className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label  className="form-label">Password</label>
                                    <input type="password" name='password' value={inputs.password || ""}  onChange={handleChange}  className="form-control" />
                                </div>
                                <button type="submit" className="btn btn-primary w-100"><i className="fas fa-door-open"></i> เข้าสู่ระบบ</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home