import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProductCard from '../components/ProductCard';
import ProductSlide from '../components/ProductSlide';
import { Link, useParams } from 'react-router-dom';
import SimplePage from '../components/SimplePage';
import NormalPage from '../components/NormalPage';
import React, { useState , useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function SelectModelPage() {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const { taste, size } = useParams();
    const [ totalCart, setTotalCart ] = useState(0);
    function setCart(c){
        setTotalCart(c)
    }

    useEffect(() => {
        async function loadCart(){
          let newObject = localStorage.getItem("cart");
          setTotalCart(JSON.parse(newObject).length)
        }
        loadCart()
    } , [])
    
    
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false) 
    };
    const [modalImage , setModalImage] = useState(null)
    const handleShow = (e) => {
        setModalImage(e)
        setShow(true)
    };

    let themeType
    if(taste == 'original' || taste == 'yusu_blossoms' || taste == 'choco_berries'){
        if(size == 'simple'){
            themeType = 'simple'
        }
    }else{
        themeType = 'normal'
    }

    return (
        <div className="SelectModelPage">
            <Header totalCart={totalCart}/>
            <div className='container my-5'>
                <div className='select-size-card'>
                    {themeType == 'simple' ? <SimplePage handleShow={handleShow} setCart={setCart}/> : <NormalPage handleShow={handleShow} setCart={setCart}/>}
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>รูปสินค้า</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={modalImage} id="modalImage"  className='w-100 rounded'/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='margin-footer'></div>
            <Footer />
        </div>
    )
}
export default SelectModelPage