import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Axios from 'axios'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'js-cookie'
import { useState , useContext , useEffect } from 'react';
import { AuthContext } from '../components/Auth'
const Swal = withReactContent(swal)



function LoginPage(){

        const dataObj = {token: localStorage.getItem('token')}
        useEffect(() => {
            Axios.post('https://berriesblossoms.com:3000/get/user' , dataObj).then(res => {
                var res = res.data
                if(res.status == 'success'){
                    navigate('/')
                }
            })
        })
        
        const [inputs, setInputs] = useState({});
        const navigate = useNavigate()
        const handleChange = (event) => {
            const name = event.target.name;
            const value = event.target.value;
            setInputs(values => ({...values, [name]: value}))
        }
    
        const handleSubmit = (event) => {
            event.preventDefault();
            const dataObj = {phone:inputs.phone}
            Axios.post('https://berriesblossoms.com:3000/login' , dataObj).then(res => {
                var res = res.data
                if(res.loggedIn){
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน</strong>,
                        html: <small>เข้าสู่ระบบสำเร็จ</small>,
                        icon: 'success'
                    }).then(() => {
                        localStorage.setItem('token' , res.token)
                        Cookies.set('user_fullname', res.user.fullname , { expires: 365 })
                        navigate('/')
                    })
                }else{
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน</strong>,
                        html: <small>{res.msg}</small>,
                        icon: 'error'
                    })
                }
            })
        }

        return (
            <div>
                <Header/>
                <div className='loginBg'>
                    <div className='container py-5'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-4'>
                                <div className='loginSection'>
                                    <div className='whiteCard'>
                                        <h4 className='text-center mb-4'>ลูกค้าเก่า</h4>
                                        <form id="loginForm" onSubmit={handleSubmit}>
                                            <input type="hidden" id="form_type" name='form_type' value="login" />
                                            <div className='form-group'>
                                                <label><i className='far fa-phone'></i> เบอร์โทรศัพท์</label>
                                                <input type="text" name='phone' value={inputs.phone || ""} onChange={handleChange} id="phone" className="form-control" required/>
                                            </div>
                                            {/* <div className='row mb-3 mb-3'>
                                                <div className='col-6'>
                                                    <a target="_blank" href="https://www.facebook.com/berries.blossoms" type='button' className='add-friend facebook'><i className='fab fa-facebook'></i><span> FACEBOOK</span><br></br>Add Friend</a>
                                                </div>
                                                <div className='col-6'>
                                                    <a target="_blank" href="https://line.me/R/ti/p/%40berries_blossoms"  type='button' className='add-friend line'><i className='fab fa-line'></i><span>  LINE</span><br></br>Add Friend</a>
                                                </div>
                                            </div> */}
                                            <button type='submit'  className='submit-btn'><i className='fad fa-door-open'></i> เข้าสู่ระบบ</button>
                                            <hr/>
                                            <h4 className='text-center mb-4'>ลูกค้าใหม่</h4>
                                            <div className='d-flex'>
                                                <Link to="/register" className='new-user-btn'><i className='fad fa-user-plus'></i> เริ่มต้นที่นี่</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
}

export default LoginPage