
import { Link } from 'react-router-dom';
function SelectTaste(){

    return (
        
        <div className='container'>
          <div className='d-flex'>
            <h4 className='mb-3 select-taste-title'>เลือกรสชาติ/สั่งซื้อ</h4>
          </div>
          <div className='select-taste-group'>
            <div className='taste-item'>
              <Link to="/select_size/original/#">
                <div className='taste-content first'>
                  <h3>Original</h3>
                  <h1>1</h1>
                </div>
              </Link>
            </div>
            <div className='taste-item'>
              <Link to="/select_size/fruity_berries/#">
                <div className='taste-content second'>
                  <h3>Fruity Berries</h3>
                  <h1>2</h1>
                </div>
              </Link>
            </div>
            <div className='taste-item'>
              <Link to="/select_size/yusu_blossoms/#">
                <div className='taste-content third'>
                  <h3>Yuzu Blossoms</h3>
                  <h1>3</h1>
                </div>
              </Link>
            </div>
            <div className='taste-item'>
              <Link to="/select_size/yusu_premium/#">
                <div className='taste-content four'>
                  <h3>Yuzu Premium</h3>
                  <h1>4</h1>
                </div>
              </Link>
            </div>
            <div className='taste-item'>
              <Link to="/select_size/choco_berries/#">
                <div className='taste-content five'>
                  <h3>Choco Berries</h3>
                  <h1>5</h1>
                </div>
              </Link>
            </div>
            <div className='taste-item'>
              <Link to="/select_size/special_event/#">
                <div className='taste-content six'>
                  <h3>Special Event</h3>
                  <h1>6</h1>
                </div>
              </Link>
            </div>
          </div>
        </div>
    )
}

export default SelectTaste