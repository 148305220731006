import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Axios from 'axios'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'js-cookie'
import { useState , useContext , useEffect } from 'react';
import { AuthContext } from '../components/Auth'
const Swal = withReactContent(swal)



function ForgotPassword(){
    const navigate = useNavigate()
    const handleSubmit = (event) => {
        event.preventDefault();
        const dataObj = new FormData(event.target)
        Axios.post('https://berriesblossoms.com:3000/forgot_password' , dataObj).then(res => {
            var res = res.data
            if(res.success){
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>เราได้ส่งลิ้งค์รีเซ็ทรหัสผ่านไปยังอีเมล์ของคุณแล้ว โปรดตรวจสอบที่ กล่องจดหมาย หรือ จดหมายขยะ</small>,
                    icon: 'success'
                }).then(() => {
                    window.location.reload(false);
                })
            }else{
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>{res.msg}</small>,
                    icon: 'error'
                })
            }
        })
    }
    
        return (
            <div>
                <Header/>
                <div className='loginBg'>
                    <div className='container py-5'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-5'>
                                <div className='loginSection'>
                                    <div className='whiteCard'>
                                        <h4 className='text-center mb-4'>ลืมรหัสผ่าน</h4>
                                        <form id="loginForm" onSubmit={handleSubmit}>
                                            <div className='form-group'>
                                                <label><i className="fas fa-envelope"></i> อีเมล์</label>
                                                <input type="text" name='email' id="email" placeholder='อีเมล์ที่ใช้สมัครสมาชิก' className="form-control" required/>
                                            </div>
                                            <button type='submit'  className='submit-btn'><i class="fas fa-paper-plane"></i> ส่งลิ้งค์</button>
                                        </form>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
}

export default ForgotPassword