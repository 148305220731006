import Header from "../components/Header"
import ProductForm from "../components/ProductForm";
import Axios from 'axios'
import React, { useState, useContext, useEffect } from 'react';
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const Swal = withReactContent(swal)

export default function Landing(){
    const [ showImage , setShowImage ] = useState('https://dummyimage.com/1200x800/d6d6d6/8a8a8a')
    const [ status , setStatus ] = useState(0)

    useEffect(() => {
        Axios.get('https://berriesblossoms.com:3000/get/landing', {}).then(res => {
            setStatus(res.data.landing_status)
            setShowImage(res.data.image)
            if(res.data.landing_status == 1){
                document.getElementById('statusCheck').checked = true
            }
        })
    },[]) 

    const updateLandingPage = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const file = document.getElementById('image')
        if(file.files.length == 0 ){
            formData.append('image_name' , 'none')
            formData.append('status' , status)
            Axios.post('https://berriesblossoms.com:3000/update/landing' , formData).then(res => {
                if(res.data.success){
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน</strong>,
                        html: <small>บันทึกการตั้งค่าสำเร็จ</small>,
                        icon: 'success'
                    }).then(() => {
                        window.location.reload(false);
                    })
                }else{
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน</strong>,
                        html: <small>บันทึกการตั้งค่าไม่สำเร็จโปรดลองใหม่อีกครั้ง</small>,
                        icon: 'error'
                    })
                }
            })
        } else {
            Axios.post('https://berriesblossoms.com:3001/uploads.php?type=one' , formData).then(imageRes => {
                formData.append('image_name' , imageRes.data.cover_image)
                formData.append('status' , status)
                Axios.post('https://berriesblossoms.com:3000/update/landing' , formData).then(res => {
                    if(res.data.success){
                        Swal.fire({
                            title: <strong>ระบบแจ้งเตือน</strong>,
                            html: <small>บันทึกการตั้งค่าสำเร็จ</small>,
                            icon: 'success'
                        }).then(() => {
                            window.location.reload(false);
                        })
                    }else{
                        Swal.fire({
                            title: <strong>ระบบแจ้งเตือน</strong>,
                            html: <small>บันทึกการตั้งค่าไม่สำเร็จโปรดลองใหม่อีกครั้ง</small>,
                            icon: 'error'
                        })
                    }
                })
            })
        }
    }

    const actionStatus = (e) => {
        if(status == 1){
            setStatus(0)
        }else{
            setStatus(1)
        }
    }

    return (
        <div>
            <Header page="landing"/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <form onSubmit={updateLandingPage} className="card">
                            <div className="card-header">
                                <h6 className="mb-0">ตั้งค่าหน้า Landing Page</h6>
                            </div>
                            <div className="card-body">
                                <img src={showImage} className="w-100" />
                                <div className="mt-4 mb-3">
                                    <p className="mb-2">แก้ไขรูปภาพ (1200 x 800)</p>
                                    <input type="file" name="cover_image" id="image" className="form-control"  />
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" value="1" onChange={actionStatus} id="statusCheck"  />
                                    <label className="form-check-label" htmlFor="statusCheck">ใช้งาน Landing Page</label>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-sm w-100 btn-success" >บันทึก</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}