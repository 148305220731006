import Header from "../components/Header"
import Axios from 'axios'
import { useParams, useNavigate,withRouter  } from 'react-router-dom';
import React , { useState , useContext , useEffect } from 'react';
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProductAdminCard from "../components/ProductAdminCard";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const Swal = withReactContent(swal)

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function SelectSize(){
    const queryParams = new URLSearchParams(window.location.search);
    const taste = queryParams.get('taste');
    const size = queryParams.get('size');

    const [sizeSelect, setSizeSelect] = useState(size)

    const selectSize = (e) => {
        const sizes = document.getElementsByClassName('select-size')
        for (let i = 0; i < sizes.length; i++) {
            sizes[i].classList.remove("active");
            sizes[i].innerHTML = sizes[i].dataset.size
        }
        e.target.classList.add('active')
        e.target.innerHTML = "<i class='fad fa-check-circle me-2'></i>" + e.target.dataset.size
        setSizeSelect(e.target.dataset.size)
        
    }

    return(
        <div className="row">
            <div className="col-md-8">
                <div className='select-size-group'>
                    <div className='select-size-item' >
                        <div className='select-size ' data-size="Simple" onClick={selectSize}>
                            Simple
                        </div>
                    </div>
                    <div className='select-size-item' >
                        <div className='select-size active' data-size="Merry" onClick={selectSize}>
                            <i className='fad fa-check-circle me-2'></i> Merry
                        </div>
                    </div>
                    <div className='select-size-item'>
                        <div className='select-size ' data-size="Love" onClick={selectSize}>
                            Love
                        </div>
                    </div>
                    <div className='select-size-item' >
                        <div className='select-size ' data-size="Extra Love" onClick={selectSize}>
                            Extra Love
                        </div>
                    </div>
                    <div className='select-size-item' >
                        <div className='select-size ' data-size="Forever" onClick={selectSize}>
                            Forever
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ProductForm() {
    const queryParams = new URLSearchParams(window.location.search);
    const taste = queryParams.get('taste');
    const size = queryParams.get('size');
    
    const [sizeSelect, setSizeSelect] = useState(size)
    const [myProducts , setMyProducts] = useState([])

    const [inputs , setInputs] = useState({})
    const handleAddChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setInputs(values => ({...values , [name]: value}))
    }
    

    const [selectedFile, setSelectedFile] = useState();

    const changeFileHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};
   
    const addProductSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('name' , inputs.name)
        formData.append('price' , inputs.price)
        formData.append('taste' , taste)
        formData.append('size' , sizeSelect)
        formData.append('cover_image' , selectedFile)
        if(!setSelectedFile){
            Swal.fire({
                title: <strong>ระบบแจ้งเตือน</strong>,
                html: <small>กรุณาเลือกรูปภาพ</small>,
                icon: 'error'
            })
            return false
        }
        
        Axios.post('https://berriesblossoms.com:3000/add/product' , formData).then(response => {
            const res = response.data
            if(res.success){
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>เพิ่มสินค้าสำเร็จ</small>,
                    icon: 'success'
                }).then(() => {
                    window.location.reload(false);
                })
            }else{
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>{res.msg}</small>,
                    icon: 'error'
                })
            }
        })
    }

    return (
        <div className="modal fade" tabIndex="-1" id="myModal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">เพิ่มสินค้า</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group-file mb-3">
                                <label>รูปสินค้า</label>
                                <br></br>
                                <input type="file" name="cover_image" onChange={changeFileHandler} className="form-control-file mt-2"  />
                            </div>
                            <div className="form-group">
                                <label>ชื่อสินค้า</label>
                                <input type="text" name="name" value={inputs.name || ""} onChange={handleAddChange} className="form-control"  required/>
                            </div>
                            <div className="form-group">
                                <label>ราคาสินค้า</label>
                                <input type="number" name="price" value={inputs.price || ""} onChange={handleAddChange} className="form-control"  required/>
                            </div>
                            <div className="modal-footer p-0 border-0">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">ยกเลิก</button>
                                <button type="submit" className="btn btn-primary">
                                    บันทึก
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

class Products extends React.Component {
    state = {
        products: [],
        selectSize: null,
        selectTaste: null,
        openModal : false
    }

    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search);
        const taste = queryParams.get('taste');
        const size = queryParams.get('size');
        this.setState({ selectSize: size,selectTaste:taste });
       
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const taste = queryParams.get('taste');
        const size = queryParams.get('size');
        Axios.post('https://berriesblossoms.com:3000/get/product' , { taste:taste, size:size }).then(response => {
            const res = response.data
            this.setState({ products: res.products });
        }) 

    }
    
    selectSize = (e) => {
        const queryParams = new URLSearchParams(window.location.search);
        const taste = queryParams.get('taste');
        const size = queryParams.get('size');
        const sizes = document.getElementsByClassName('select-size')
        for (let i = 0; i < sizes.length; i++) {
            sizes[i].classList.remove("active");
            sizes[i].innerHTML = sizes[i].dataset.size.charAt(0).toUpperCase() + sizes[i].dataset.size.slice(1);
        }
        e.target.classList.add('active')
        const sizeName = e.target.dataset.size.charAt(0).toUpperCase() + e.target.dataset.size.slice(1);
        e.target.innerHTML = "<i class='fad fa-check-circle me-2'></i>" + sizeName
        Axios.post('https://berriesblossoms.com:3000/get/product' , { taste:taste, size:e.target.dataset.size}).then(response => {
            const res = response.data
            this.setState({ products: res.products });
        }) 
  
    }

    componentWillUnmount() {
       
    }


    
    render() {
        return (
        <div>
            <Header />
            <div className="container mt-5">
                <div className="d-flex">
                    <h4>จัดการสินค้า</h4>
                    <button className="ms-auto btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal"><i className="fas fa-plus"></i> เพิ่มสินค้า</button>
                </div>
                <div className="row">
                <div className="col-md-8">
                    <div className='select-size-group'>
                        <div className='select-size-item' >
                            <div className='select-size ' data-size="simple" onClick={this.selectSize}>
                                Simple
                            </div>
                        </div>
                        <div className='select-size-item' >
                            <div className='select-size active' data-size="merry" onClick={this.selectSize}>
                                <i className='fad fa-check-circle me-2'></i> Merry
                            </div>
                        </div>
                        <div className='select-size-item'>
                            <div className='select-size ' data-size="love" onClick={this.selectSize}>
                                Love
                            </div>
                        </div>
                        <div className='select-size-item' >
                            <div className='select-size ' data-size="extra Love" onClick={this.selectSize}>
                                Extra Love
                            </div>
                        </div>
                        <div className='select-size-item' >
                            <div className='select-size ' data-size="forever" onClick={this.selectSize}>
                                Forever
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className="row mt-5" id="products">
                    {this.state.products.length ? this.state.products.map((product) => (
                        <ProductAdminCard product={product} />
                    )) : <div className="col-12 mt-5"><h1 className="text-center text-muted">ขออภัย, ไม่มีข้อมูล</h1></div>}
                </div>
            </div>
            <ProductForm/>

        </div>
        )
    }
}

export default Products