import Header from "../components/Header"
import DataTable from 'react-data-table-component';
import OrderTable from '../components/OrderTable'
import React , { useEffect , useState } from 'react'
import Axios from "axios";
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const Swal = withReactContent(swal)


function Banner(){
    const queryParams = new URLSearchParams(window.location.search);
    const mType = queryParams.get('type');
    const page = mType == 'banner' ? 'banners' : 'products' 
    const deleteImage = (id) => {
        Swal.fire({
            title: 'คุณต้องการที่จะรูปภาพสินค้า ?',
            showDenyButton: true,
            confirmButtonText: 'ยืนยัน',
            denyButtonText: `ยกเลิก`,
            }).then((result) => {
            if (result.isConfirmed) {
                Axios.post('https://berriesblossoms.com:3000/delete/banner' , {id:id} ).then(response => {
                    if(response.data.success){
                        Swal.fire({
                            title: <strong>ระบบแจ้งเตือน</strong>,
                            html: <small>ลบรูปภาพสำเร็จ!!</small>,
                            icon: 'success'
                        }).then(() => {
                            window.location.reload(false);
                        })
                    }else{
                        Swal.fire('บันทึกไม่สำเร็จ ', 'โปรดลองใหม่อีกครั้ง', 'error')
                    }
                })
            }
        })
    }
    
            
    const [banners , setBanners] = useState([])
    const addBannerSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        Axios.post('https://berriesblossoms.com:3001/uploads.php?type=one' , formData).then(imageRes => {
            if(imageRes.data.success){
                const ojb = {image_name: imageRes.data.cover_image , image_type:mType}
                Axios.post('https://berriesblossoms.com:3000/add/banner' , ojb).then(res => {
                    if(res.data.success){
                        Swal.fire({
                            title: <strong>ระบบแจ้งเตือน</strong>,
                            html: <small>เพิ่มรูปภาพสำเร็จ</small>,
                            icon: 'success'
                        }).then(() => {
                            window.location.reload(false);
                        })
                    }else{
                        Swal.fire({
                            title: <strong>ระบบแจ้งเตือน</strong>,
                            html: <small>เพิ่มรูปภาพไม่สำเร็จโปรดลองใหม่อีกครั้ง</small>,
                            icon: 'error'
                        })
                    }
                })
            }else{
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>อัพโหลดรูปภาพไม่สำเร็จ</small>,
                    icon: 'error'
                })
            }
        })
    }

    useEffect(() => {
        Axios.post('https://berriesblossoms.com:3000/get/banner' , {image_type:mType}).then(imageRes => {
            setBanners(imageRes.data.banners)
        })
    }, [])

    const bannersEl = banners.map((banner , index) => {
        const image = "https://berriesblossoms.com:3001/"+banner.cover_image
        return  <div className="col-md-4 mb-3" key={index}>
                    <div className="position-relative">
                        <button className="position-absolute top-0 end-0 mt-2 me-2 delete-image" onClick={() => deleteImage(banner._id)}><i className="fas fa-trash"></i></button>
                        <img src={image} className="w-100 rounded"/>
                    </div>
                </div>
    })

    return (
        <div>
            <Header page={page}/>
            <div className="container mt-5">
                <div className="d-flex align-middle mb-3">
                    <h4 className="mb-0">จัดการรูปภาพ</h4>
                    <button className="btn btn-sm btn-primary ms-auto" data-bs-toggle="modal" data-bs-target="#addBannerModal">เพิ่มรูปภาพ</button>
                </div>
                <div className="admin-card">
                    <div className="row">
                        {bannersEl}
                    </div>
                </div>
            </div>

            <div className="modal fade" tabIndex="-1" id="addBannerModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">เพิ่มรูปภาพ</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={addBannerSubmit}>
                                <div className="form-group-file mb-3">
                                    <label>รูปภาพ {mType == 'banner' ? '(ขนาด 1900x600)' : '(ขนาด 800x800)' }</label>
                                    <br></br>
                                    <input type="file" name="cover_image" className="form-control-file mt-2"  required/>
                                </div>
                                <div className="modal-footer p-0 border-0">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">ยกเลิก</button>
                                    <button type="submit" className="btn btn-primary">
                                        บันทึก
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner