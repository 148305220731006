import Header from "../components/Header"
import DataTable from 'react-data-table-component';
import OrderTable from '../components/OrderTable'
import React , {useEffect , useState} from 'react'
import swal from 'sweetalert2'
import Axios from 'axios'
import withReactContent from 'sweetalert2-react-content'
const Swal = withReactContent(swal)

function Home(){
    const videoRef = React.createRef()
    const iframeRef = React.createRef()
    useEffect(() => {
        Axios.post('https://berriesblossoms.com:3000/get/video').then(response => {
            const res = response.data
            videoRef.current.value = res.video
            var regExp =    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;  
            var match = res.video.match(regExp);  
            if (match && match[7].length == 11) {    
                iframeRef.current.src = "https://www.youtube.com/embed/"+match[7]
            }
        })   
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        const obj = {youtube: videoRef.current.value}
        Axios.post('https://berriesblossoms.com:3000/update/video' , obj).then(response => {
            const res = response.data
            if(res.success){
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>แก้ไขวีดีโอสำเร็จ</small>,
                    icon: 'success'
                }).then(() => {
                    window.location.reload(false);
                })
            }else{
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>แก้ไขวีดีโอไม่สำเร็จ โปรดลองใหม่อีกครั้ง</small>,
                    icon: 'error'
                })
            }
        })
    }
    return (
        <div>
            <Header page="video"/>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="mb-0">จัดการวีดีโอ</h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>ลิ้งค์ youtube</label>
                                        <input type="text" ref={videoRef}  className="form-control" required  />
                                        <button type="submit" className="btn btn-sm btn-warning w-100 mt-3"><i className="fas fa-save"></i> บันทึก</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <div className="card-header">
                                <h4 className="mb-0">ตัวอย่าง</h4>
                            </div>
                            <div className="card-body">
                                <iframe  width="100%" ref={iframeRef} height="300" src="" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home