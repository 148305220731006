import Header from "../components/Header"
import DataTable from 'react-data-table-component';
import OrderTable from '../components/OrderTable'

function Home(){
    return (
        <div>
            <Header page="orders"/>
            <div className="container mt-5">
                <h4>คำสั่งซื้อ</h4>
                <div className="admin-card">
                    <OrderTable/>
                </div>
            </div>
        </div>
    )
}

export default Home