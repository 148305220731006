import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Axios from 'axios'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'js-cookie'
import React , { useState , useContext , useEffect } from 'react';
import { AuthContext } from '../components/Auth'
const Swal = withReactContent(swal)


function ResetPassword(){
        const [email , setEmail] = useState(null)
        const password = React.createRef()
        const confirm_password = React.createRef()
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        const navigate = useNavigate()
        useEffect(() => {
            Axios.post('https://berriesblossoms.com:3000/check_resetpassword_token' , {token:token}).then(res => {
                var res = res.data
                if(!res.success){
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน</strong>,
                        html: <small>{res.msg}</small>,
                        icon: 'error'
                    }).then(() => {
                        navigate('/forgot_password')
                    })
                }else{
                    setEmail(res.email)
                }
            })
        })

        const handleSubmit = (event) => {
            event.preventDefault();
            const dataObj = {password:password.current.value , email: email}
            if(password.current.value != confirm_password.current.value){
                Swal.fire({
                    title: <strong>ระบบแจ้งเตือน</strong>,
                    html: <small>กรุณาระบุรหัสผ่านให้ตรงกัน</small>,
                    icon: 'error'
                })
            }
            Axios.post('https://berriesblossoms.com:3000/reset_password' , dataObj).then(res => {
                var res = res.data
                if(res.success){
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน</strong>,
                        html: <small>รีเซ็ทรหัสผ่านสำเร็จ !!</small>,
                        icon: 'success'
                    }).then(() => {
                        navigate('/login')
                    })
                }else{
                    Swal.fire({
                        title: <strong>ระบบแจ้งเตือน</strong>,
                        html: <small>{res.msg}</small>,
                        icon: 'error'
                    })
                }
            })
        }
    
        return (
            <div>
                <Header/>
                <div className='loginBg'>
                    <div className='container py-5'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-5'>
                                <div className='loginSection'>
                                    <div className='whiteCard'>
                                        <h4 className='text-center mb-4'>แก้ไขรหัสผ่าน</h4>
                                        <form id="loginForm" onSubmit={handleSubmit}>
                                            <div className='form-group'>
                                                <label><i className="fas fa-key"></i> รหัสผ่าน</label>
                                                <input type="password" name='password' id="password" ref={password}  className="form-control" required/>
                                            </div>
                                            <div className='form-group'>
                                                <label><i className="fas fa-key"></i> ยืนยันรหัสผ่าน</label>
                                                <input type="password" name='confirm_password' ref={confirm_password} id="confirm_password"  className="form-control" required/>
                                            </div>
                                            <button type='submit'  className='submit-btn'><i className="fas fa-save"></i> เปลี่ยนแปลงรหัสผ่าน</button>
                                        </form>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
}

export default ResetPassword