// import banner from '../assets/imgs/banner-1.jpg'
import { useParams , Link , useNavigate, withRouter } from 'react-router-dom';
import React, { useRef , useState, useContext, useEffect } from 'react';
import Axios from 'axios'

function Banner(){
    const nextRef = React.useRef()
    const [banners , setBanners] = useState([])
    useEffect(() => {
        Axios.post('https://berriesblossoms.com:3000/get/banner' , {image_type:'banner'}).then(imageRes => {
            setBanners(imageRes.data.banners)
        })
    }, [])

    let i = -1
    const bannersEl = banners.map((banner , index) => {
        i++
        const image = "https://berriesblossoms.com:3001/"+banner.cover_image
        return  <div className={`carousel-item ${i == 0 ? 'active' : ''}`} key={index}  data-bs-interval="5000">
                    <img src={image} className="d-block w-100 rounded" alt="..."/>
                </div>
                
    })
    var ind = -1
    const bannersIndicatorsEl = banners.map((banner , index) => {
        ind++
        return  <button type="button" key={index} data-bs-target="#mainSlide" data-bs-slide-to={ind} className={ind == 0 ? 'active' : ''} aria-current="true" ></button>
    })

  
    useEffect(() => {
        setTimeout(function() {
            const click = nextRef.current;
            click.click()
        }, 5000);
    }, [])
    

    return (
        <div className='container mt-3'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='banner'>
                        <div id="mainSlide"  className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                {bannersIndicatorsEl}
                            </div>
                            <div className="carousel-inner">
                                {bannersEl}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#mainSlide" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" ref={nextRef}  type="button" data-bs-target="#mainSlide" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
  
  export default Banner;