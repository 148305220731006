function AboutUs(){
    return (
      <div className="container">
        <div className='about-us'>
          <div className='row mt-5'>
            <div className='col-md-6  mb-3'>
              <h2>Berries n' Blossoms  - Premium Healthy Desserts </h2>
              <p className='text-indent30'>
              3D Jelly​ Art​ Cake​ ของ​ Berries​ n' Blossoms​  มักถูกเรียกง่ายๆว่า​ "วุ้นเบอร์รี่​ดอกไม้" ตามเอกลักษณ์​ที่ไม่เหมือนใคร​ คือเป็นลวดลายดอกไม้ที่อยู่บนฐานวุ้นรสเบอร์รี่​รวม​ เปรี้ยวนิดๆจากผลไม้ตระกูล​เบอร์รี่​ 
              หวานหน่อย​ๆจากน้ำผึ้งและน้ำหวานจากดอกมะพร้าว​ ปรุงด้วยสูตรเฉพาะจนได้รสชาติ​อร่อยกลมกล่อม​ละมุน​ละไม​ 
              </p> 
              <p className='text-indent30'>เนื้อวุ้น​ใสๆ​ทำ​จาก​สาหร่าย​ทะเล​น้ำ​ลึก​ <br className="xsm-d-block"/>รสหวานอ่อนๆ​</p>
              <p className='text-indent30'>
              ลวดลาย​ใช้​ผลิตภัณฑ์​จากพืช(ปราศจากไขมันและคลอเรสเตอรอล)​ผสมสีที่สกัด​จากผลไม้และดอกไม้​ตาม​ธรรมชาติ​ ทุกคำที่เคี้ยวกลิ่นหอมอ่อนๆ​ของดอกไม้และ​ผลไม้​แต่ละชนิดจะอบอวลอยู่ในปาก
              </p> 
              <p className='text-indent30'>
              เมื่อรับประทาน​พร้อมกันทั้งเนื้อวุ้น​ใส ลายดอกไม้​และฐานเบอร์รี่​ รสชาติ​ที่ได้จึงมีทั้ง​เปรี้ยว​ หวาน​ และหอมสดชื่นไปพร้อมๆกัน
              </p> 
            </div>
            <div className='col-md-6 mb-3'>
              <img src='images/img-con.png' className="w-100"></img>
            </div>
            <div className="w-100 my-3"></div>
            <div className="col-md-6 mb-3">
              <img src="../../images/healthy_color.jpg" className="w-100" />
            </div>
            <div className="col-md-6 mb-3">
              <h2>Berries​ n' Blossoms​ ใช้วัตถุ​ดิบจากธรรมชาติ​ สร้างสีสันและลวดลายในเนื้อวุ้น​ </h2>
              <h5>สีทุกสีสกัดมาจากดอกไม้และผลไม้ 100%</h5>
              <br/>
              <h5 className="color-list yellow"><i class="fas fa-circle"></i> สีเหลือง​ จาก​ ผลมะม่วง​</h5>
              <h5 className="color-list orange"><i class="fas fa-circle"></i> สีส้ม​ จาก​ ผลส้ม​</h5>
              <h5 className="color-list lightblue"><i class="fas fa-circle"></i> สีฟ้า​ จาก​ ดอกอัญชัน</h5>
              <h5 className="color-list purple"><i class="fas fa-circle"></i> สีม่วง​ จาก​ ผลบลูเบอร์รี่</h5>
              <h5 className="color-list lightpink"><i class="fas fa-circle"></i> สีชมพู​อ่อน จาก​ ดอกกุหลาบ</h5>
              <h5 className="color-list darkpink"><i class="fas fa-circle"></i> สีชมพูเข้ม​ จาก​ ส​ต​รอว์​เบ​อร์รี่​</h5>
              <h5 className="color-list white"><i class="fas fa-circle"></i> สีขาว​ จาก​ ดอกมะลิ</h5>
              <h5 className="color-list darkgreen"><i class="fas fa-circle"></i> สีเขียว​เข้ม จาก​ พรีเมียมมัทฉะ</h5>
              <h5 className="color-list lightgreen"><i class="fas fa-circle"></i> สีเขียว​อ่อน​ จาก​ ใบเตย​</h5>
            </div>
          </div>
        </div>
      </div>
    )
}

export default AboutUs