import React , { useState , useEffect } from "react";
import ReactDOM from "react-dom";
import Axios from 'axios'
import { Link , useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const Swal = withReactContent(swal)

function Status(props){
  let status
  if(props.status == 0){
      status = <select class="btn btn-sm btn-warning mt-2" onChange={() => changeStatus(props.order_id, props.status)}>
                  <option value="0" selected>รอดำเนินการ</option>
                  <option value="1">จัดส่งแล้ว</option>
                </select>
  }else if(props.status == 1){
      status = <select class="btn btn-sm btn-warning mt-2" onChange={() => changeStatus(props.order_id , props.status)}>
                <option value="0">รอดำเนินการ</option>
                <option value="1" selected>จัดส่งแล้ว</option>
              </select>
  }

  return (
      <span>
          {status}
      </span>
  )
}

function changeStatus(order_id , status){
    Axios.post('https://berriesblossoms.com:3000/update/order', {order_id:order_id , status:status}).then(response => {
        const res = response.data
        if(res.success){
          Swal.fire({
            title: <h4>แก้ไขสถานะสำเร็จ</h4>,
            html: "",
            icon: "success"
          }).then(() => {
            window.location.reload(false);
          })
        }else{
          Swal.fire({
            title: <h4>แก้ไขสถานะไม่สำเร็จ โปรดลองใหม่อีกครั้ง</h4>,
            html: "",
            icon: "error"
          })
        }
    })
}

function ViewButton(props){
  const navigate = useNavigate()
  function viewDetail(){
      navigate('/order/'+props.id)
  }
  return (
      <button type="button" onClick={viewDetail} className="btn btn-sm btn-info text-white">ดูรายละเอียด</button>
  )
}

function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
  const results = [];

  for (let i = 1; i < pages; i++) {
    results.push(i);
  }

  return results;
}

const columns = [
  {
    name: <h6>เลขคำสั่งซื้อ</h6>,
    selector: (row) => <h6 className="text-danger">{row.order_id}</h6>,
    sortable: true
  },
  {
    name: <h6>วันที่สั่งซื้อ</h6>,
    selector: (row) => <h6>{new Intl.DateTimeFormat('th-TH', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(row.created_at)}</h6>,
    sortable: true
  },
  {
    name: <h6>สถานะคำสั่งซื้อ</h6>,
    selector: (row) =>  <h6><Status status={row.status} order_id={row._id}/></h6>,
    sortable: true
  },
  {
    name: "",
    selector: (row) =>  <ViewButton id={row._id}/>,
    sortable: false
  }
];

// RDT exposes the following internal pagination properties
const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage
}) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page">
            กลับ
          </button>
        </li>
        {pageItems.map((page) => {
          const className =
            page === currentPage ? "page-item active" : "page-item";

          return (
            <li key={page} className={className}>
              <button
                className="page-link"
                onClick={handlePageNumber}
                value={page}
              >
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page"
          >
            ต่อไป
          </button>
        </li>
      </ul>
    </nav>
  );
};

const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check">
    <input
      htmlFor="booty-check"
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="form-check-label" id="booty-check" />
  </div>
));

function OrderTable() {
  const [orders , setOrders]  = useState([])
  const [loading , setLoading]  = useState(false)
  useEffect(() => {
      async function loadOrders(){
          setLoading(true)
          Axios.post('https://berriesblossoms.com:3000/admin/orders').then(response => {
              const res = response.data
              setOrders(res.orders)
          })
          setLoading(false)
      }
      loadOrders()
  }, [])

  return (
    <div className="App">
      <div className="card">
        <DataTable
          columns={columns}
          data={orders}
          defaultSortFieldID={1}
          pagination
          paginationComponent={BootyPagination}
        />
      </div>
    </div>
  );
}

export default OrderTable
