import Axios from 'axios'
import React, { useState,  useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

export default function CheckLading(){
    const navigate = useNavigate();
    const [ showImage , setShowImage ] = useState('https://dummyimage.com/1200x800/d6d6d6/8a8a8a')
    const [ status , setStatus ] = useState(0)

    

    useEffect(() => {
        Axios.get('https://berriesblossoms.com:3000/get/landing', {}).then(res => {
            if(res.data.landing_status != 1){
                navigate('/home')
            }
            setShowImage(res.data.image)
        })
    })
    return (
        <div>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-10'>
                        <img src={showImage} className="w-100 mt-4 rounded" />
                    </div>
                    <div className='w-100'></div>
                    <div className='col-md-3 text-center'>
                        <Link className='welcome-btn' to="/home">เข้าสู่เว็บไซต์</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}