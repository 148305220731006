import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import Header from '../components/Header';
import Banner from '../components/Banner';
import ProductCard from '../components/ProductCard';
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';
import SelectTaste from '../components/SelectTaste';
import ProductSlide from '../components/ProductSlide';
import { useParams , Link , useNavigate, withRouter } from 'react-router-dom';
import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios'

function HomePage(){

    return (
    <div className='ContactUsPage'>
        <Header/>
        <div className='container'>
            <div className='my-5'>
                <div className='d-flex'>
                  <h4 className='contact-us-title text-danger'>ไม่มีสินค้าหน้าร้าน กรุณาออเดอร์ล่วงหน้า 3-5 วันนะคะ</h4>
                </div>
                <div className='row mt-5 justify-content-center'>
                  <div className='col-md-5 mb-4'>
                    <img src="../images/contact_us.jpg"  className='w-100 rounded'/>
                  </div>
                  <div className='col-md-6'>
                    <p className='contact-info'><i class="fas fa-map-marker-alt icon-adress"></i> ที่อยู่: 12 ซอย จรัญสนิทวงศ์ 91 แขวง บางอ้อ เขตบางพลัด กรุงเทพมหานคร 10700</p>
                    <p className='contact-info'><i class="fas fa-hourglass-start icon-time"></i> เวลาทำการ: <span className='text-success'>เปิด 09.00</span> ⋅ ปิด 18:00</p>
                    <p className='contact-info'><i class="fas fa-phone-square-alt icon-phone"></i> โทรศัพท์: <a className='link-normal' href="tel:0859179045">085 917 9045</a></p>
                    <p className='contact-info'><i class="fas fa-envelope-square icon-email"></i> Email : berriesblossoms2020@gmail.com</p>
                    <p className='contact-info'><i class="fab fa-line icon-line"></i> Line Official : <a className='link-normal' href="https://line.me/R/ti/p/%40908inxwg">@berries_blossoms</a></p>
                    <p className='contact-info'><i class="fab fa-facebook icon-facebook"></i> Facebook : <a className='link-normal' href="https://www.facebook.com/berries.blossoms">Berries n' Blossoms</a></p>
                    <p className='contact-info'><i class="fab fa-instagram icon-ig"></i> Instagram : <a className='link-normal' href="https://www.instagram.com/berries.blossoms/">berries.blossoms</a></p>
                  </div>
                </div>
            </div>
        </div>
      {/* <div className='space-bottom'></div> */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.6382438327237!2d100.5101949!3d13.8006681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29b5a7be4eb83%3A0xebd91132c640d84e!2zQmVycmllc-KAiyBuJ0Jsb3Nzb21z4oCL!5e0!3m2!1sen!2sth!4v1668054700851!5m2!1sen!2sth" 
                      width="100%" 
                      height="450" 
                      style={{ border: 0 }}
                      allowFullScreen="" 
                      loading="lazy" 
                      className='rounded'
                      referrerPolicy="no-referrer-when-downgrade"></iframe>
      <Footer/>
    </div>
    )
}

export default HomePage